/*https://codepen.io/jidelambo/pen/RaRygY*/

body {
    padding: 0px !important;
    min-height: auto !important;
}

html {
    position: relative;
    min-height: 100%;
}

.bg-full-image {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    position: relative;
}

.filter-blur {
    filter: blur(5px);
}

.f-Transaction {
    width: fit-content!important;
}

.bg-full-image:before {
    background-image: url(../img/header_bg.jpg?v3) !important;
    background-size: cover !important;
    background-attachment: fixed;
    content: "";
    display: block;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 200vh !important;
}

.min-height-90vh {
    min-height: 90vh !important;
}

.manage-header {
    display: flex;
    justify-content: space-between;
}

.emd-box1 {
    background: var(--background-color-six);
    height: 90%!important;
    margin-bottom: 20px!important;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 20%);
}

.emd-box1:hover {
    box-shadow: 0px 0px 16px rgb(0 0 0 / 40%);
}

.base-footer .footer {
    position: relative !important;
    margin-top: 3em !important;
}

.custom-wrapper .footer {
    position: relative;
}

.color-white {
    color: white !important;
    cursor: pointer;
}

.bg-overlay {
    background: rgba(63, 81, 181, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
}

.all-wrapper.with-pattern {
    background-image: none !important;
    min-height: 65vh !important;
}

.main-content-container {
    padding-top: 60px;
}

.custom-content-container {
    margin-top: 0px;
}

.color-primary {
    color: #395cbe;
}

.auth-box-w.wider {
    max-width: 750px !important;
    max-width: 100%;
    border-radius: 50px !important;
}

.auth-box-w.terms-wider {
    max-width: 90% !important;
}

.auth-box-w.login-box {
    border-radius: 50px !important;
}

.auth-box-w .logo-w {
    padding: 20px 0 0 0 !important;
}

.auth-box-w {
    background-color: #395cbe !important;
    opacity: 0.95 !important;
}

.custom-form .form-control {
    padding: 18px !important;
    border-radius: 30px !important;
    border: none !important;
    color: grey !important;
    font-size: 16px !important;
}

.custom-form ::placeholder {
    color: grey !important;
    opacity: 1;
}

.custom-submit-button {
    width: 100% !important;
    border-radius: 50px !important;
    padding: 20px !important;
    background: linear-gradient(134deg, #56b8e4 0%, #6b8ccf 100%) !important;
    border: 1px solid white !important;
}

.auth-box-w .buttons-w {
    border-top: none !important;
}

.info-bottom-link {
    width: 100% !important;
    text-align: end !important;
    color: white !important;
    margin-bottom: 15px !important;
    float: right !important;
    padding-right: 15px !important;
    font-size: 18px !important;
}

.logout-link {
    color: white !important;
    float: right !important;
    padding: 5px 20px !important;
    font-size: 18px !important;
    border: 2px solid white;
    border-radius: 50px;
}

.signinpt {
    padding-top: 3.5rem !important;
}

.signin-info-bottom-link {
    text-align: end !important;
    color: white !important;
    font-size: 18px !important;
}

.terms-block {
    font-size: 18px !important;
    color: white !important;
}

.signuppage-info-bottom-link {
    color: white !important;
    font-size: 18px !important;
}

.auth-box-w form {
    padding: 20px 40px 40px 40px !important;
}

.type-selector {
    border: 1px solid white !important;
    border-radius: 50px !important;
    padding: 15px !important;
    color: white !important;
}

.copyright-text {
    font-size: 14px !important;
}

.menu-w.menu-layout-compact ul.main-menu>li>a {
    font-size: 0.9rem;
    padding: 0px 10px !important;
}

.menu-w.menu-layout-compact ul.main-menu {
    padding: 0px !important;
    margin-bottom: 0;
}

.menu-w.menu-layout-compact ul.main-menu>li {
    border-bottom: none;
    margin: 10px 0;
}

.type-selector label {
    padding-right: 15px !important;
    padding-left: 0.5rem !important;
    font-size: 16px;
}

.type-selector span {
    padding-left: 5px !important;
}

.gender-container label {
    padding-right: 10px !important;
    font-size: 16px;
}

.gender-container span {
    padding-left: 5px !important;
}


/* Styles for wrapping the search box */

.main {
    width: 50%;
    margin: 50px auto;
}

.custom-form .input-group-text {
    position: absolute !important;
    z-index: 999 !important;
    top: 11% !important;
    left: 0% !important;
    padding: 15px !important;
    border-radius: 50px !important;
    margin-left: 5px !important;
    background: #3a5ebe !important;
    color: white !important;
}

.custom-form .input-group input {
    text-indent: 3rem !important;
}

span.error {
    color: red !important;
    font-size: 15px !important;
}

.login-container .auth-box-w .buttons-w {
    padding-top: 2rem !important;
}

.custom-form .form-control {
    width: 100% !important;
}

.disabled {
    opacity: 0.9 !important;
    cursor: not-allowed !important;
}

.custom-top-bar {
    background-color: #395cbe !important;
}

.custom-bg-overlay {
    z-index: 0 !important;
}

.top-bar:not(.admin-top-bar) .top-menu-controls {
    padding: 25px 0 !important;
}

.customLogo {
    margin-left: 0px !important;
}

.custom-username {
    color: white !important;
    font-size: 16px;
}

.custom-wrapper {
    height: 100% !important;
    min-height: 100vh !important;
}

.custom-background-color {
    background-color: #fafafa !important;
    box-shadow: 0px 0px 40px rgb(0 0 0 / 20%);
    height: auto !important;
    min-height: 100vh !important;
}

.post-box .username {
    margin: 0px 20px;
}

.post-box .description {
    margin: 18px 0px;
}

.post-box .post-foot {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-dashboard-header-bg-color {
    background-color: #5e9ed8 !important;
    height: auto !important;
    min-height: auto !important;
    box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.5) !important;
}

.kyc-card-margin {
    margin: 10px 30px;
}

.custom-element-box {
    background-color: #ffffff !important;
    color: black !important;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

.custom-element-box .element-box {
    background-color: #ffffff !important;
    margin-bottom: 0 !important;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

.kyc-container .element-wrapper {
    margin: 0 2px;
}

.kyc-header {
    font-size: 20px;
    line-height: 24px;
    color: #395cbe;
    margin: 30px 0;
}

.custom-element-box .form-header {
    color: #333333 !important;
    font-size: 20px;
}

.kyc-container .form-control:not(.no-border-radius) {
    background-color: transparent !important;
    border-radius: 8px !important;
    border: 1px solid #ebebeb;
    color: #333333 !important;
}

.kyc-container ::placeholder {
    color: white;
    opacity: 0.5;
}

.permian-button:focus {
    box-shadow: 0 0 0 0.2rem rgba(4, 123, 248, 0.5) !important;
}

.kyc-container select {
    color: white !important;
}

.kyc-container .dropzone {
    background-color: #395cbe !important;
    border: 2px dashed white !important;
}

.resource-continaer {
    background-color: #395cbe !important;
}

.resource-continaer.dropzoneIdContainer {
    padding: 20px !important;
}

.country-select2 .cntry__control {
    background-color: transparent !important;
    border-radius: 8px !important;
    color: white;
}

.country-select2 ::placeholder,
.cntry__single-value,
.cntry__placeholder,
.cntry__indicators svg {
    color: black !important;
    font-size: 12px;
}

.cntry__menu {
    background-color: #b5b2b2 !important;
    color: white !important;
    z-index: 2 !important;
}

.cntry__option--is-focused {
    background-color: #888787 !important;
}

.custom-content-container {
    overflow: hidden !important;
}

.dob-container .react-datepicker-wrapper,
.dob-container .react-datepicker__input-container {
    display: inline !important;
}

.react-confirm-alert-body>h1 {
    font-size: 20px !important;
    line-height: 20px;
    margin: 12px 0px;
}

.kyc-agree-container {
    padding-left: 0px !important;
}

.kyc-agree-container label {
    font-size: 16px !important;
}

.progress-steps-container .rc-steps-item-finish .rc-steps-item-tail:after {
    background-color: white;
}

.dropzoneIdContainer {
    padding: 10px 0 !important;
}

.rdtPicker {
    background-color: #5e9ed8 !important;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
    background: #5e9ed8 !important;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
    background-color: #5e9ed8 !important;
}

.rdtPicker thead tr:first-child th:hover {
    background: #5e9ed8 !important;
}

td.rdtMonth:hover,
td.rdtYear:hover {
    background: #5e9ed8 !important;
}

.cntry__input {
    color: black !important;
    font-size: 12px;
}

.kyc-status-container {
    color: white !important;
}

.progress-icon-container {
    padding: 12px !important;
    background-color: #395cbe !important;
    border-radius: 50%;
}

.kycstatus-head {
    font-weight: 200 !important;
    font-size: 55px !important;
}

body.menu-position-side .content-w {
    border-radius: 0 !important;
}

.required-sign {
    color: red !important;
}

.admin-container,
.admin-container .menu-w,
.user-dashboard-container,
.user-dashboard-container .menu-w {
    height: 100% !important;
    min-height: 100vh !important;
    min-width: 16% !important;
}

.progress-icon-container.bg-red {
    background-color: red !important;
}

.css-1uccc91-singleValue {
    font-size: 12px !important;
}

.kyc-status-button {
    background-color: #395cbe !important;
    border-radius: 50px !important;
    padding: 15px 20px !important;
}

.text-bold {
    font-weight: bolder !important;
}

.buy-xpr-container {
    font-size: 16px !important;
}

.dark-blue-theme-color {
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

.dark-blue-font-color {
    color: #5c5e61 !important;
}

.buy-xpr-header-block {
    font-size: 16px !important;
    line-height: 22px;
}

.shareholder-btn-group {
    margin-left: 20px !important;
}

.listing-reserves-input {
    width: 90% !important;
    padding-left: 12px !important;
    height: 30px !important;
    border-radius: 8px 0 0 8px;
    opacity: 1 !important;
    border: 1px solid #ebebeb !important;
    font-size: 16px;
    font-weight: 500;
    padding-right: 10px;
}

.buy-xpr-currencey-select {
    width: 25% !important;
    height: 30px !important;
    border-radius: 0 8px 8px 0;
    background-color: #fff;
    border: 1px solid #ebebeb !important;
    padding: 0 2px !important;
    font-size: 16px;
}

.buy-xpr-currencey-select:focus {
    outline: none;
}

.buy-xpr-currencey-select option {
    background-color: #ffffff !important;
}

.buy-xpr-currencey-select,
.buy-xpr-currencey-select option {
    color: #395cbe !important;
}

.tokens-xpr-input {
    width: 20% !important;
    padding-left: 20px !important;
    height: 30px !important;
    border-radius: 50px 0px 0px 50px;
    opacity: 1 !important;
    border: white !important;
    font-size: 16px;
    font-weight: 500;
    text-align: right;
    padding-right: 10px;
}

.token-symbol-input {
    width: 7% !important;
    background-color: #59adde !important;
    border: white !important;
    opacity: 1 !important;
    border-radius: 0px 50px 50px 0px;
    height: 30px !important;
    color: white !important;
}

.highligher {
    font-size: 16px;
    font-weight: 600;
}

.receive-text {
    font-size: 16px;
}

.eth-contribution-input {
    width: 55% !important;
    border-radius: 50px !important;
    height: 30px !important;
    padding-left: 10px !important;
    border: white !important;
    opacity: 1;
    background-image: linear-gradient( 134deg, #59adde 0%, #57ccec 100%) !important;
    padding-left: 10px !important;
}

.buy-xpr-container h3,
.buy-xpr-container h6 {
    font-weight: normal !important;
    font-size: 20px;
}

.buy-xpr-buttons {
    border-radius: 50px !important;
    margin-left: 20px !important;
    background-color: transparent;
    padding: 5px 15px !important;
    font-size: 16px;
    border: 1px solid white !important;
    font-weight: 200 !important;
}

.qrcode-image-container img {
    margin-top: 15% !important;
}

.buy-online-payment-buttons {
    width: 25%;
    background-color: transparent;
    border-radius: 50px !important;
    border: 1px solid white !important;
}

.admin-error-msg,
.validation-red-error {
    color: red !important;
}

.listing-modal-container {
    padding: 0 !important;
}


/* .onboarding-modal {
    width: fit-content;
} */

.listing-modal-container .onboarding-modal .onboarding-content .onboarding-text {
    color: black !important;
}

.required-field {
    color: red;
}

.my-account-container .my-account-header-block h5 {
    font-weight: 400 !important;
}

.btn-large {
    height: 32px;
}

.account-button {
    padding: 0px 24px;
    border-radius: 8px;
    background-color: #ffffff !important;
    border: 1px solid #395cbe !important;
    color: #395cbe;
    margin: 10px 0px;
    height: 32px;
}

.account-button:focus {
    box-shadow: 0 0 0 0.2rem rgba(4, 123, 248, 0.5) !important;
    outline: none !important;
}

.my-account-button {
    height: 32px;
}

.text-left {
    text-align: left !important;
}

.my-account-container .kyc-success-icon-container {
    padding: 12px !important;
    background-color: white !important;
    color: #395cbe !important;
    border-radius: 50%;
}

.my-account-container .verified-text {
    font-size: 1.3rem !important;
}

.my-account-container h5 span {
    font-size: 20px;
}

.update-button {
    background-color: #395cbe;
    color: #ffffff;
    border: 1px solid #395cbe;
    margin: 0px 12px;
}

.update-button:hover {
    background-color: #395cbe;
    color: #ffffff;
    text-decoration: none;
}

.modal-button {
    height: 32px;
    padding: 0px 24px;
    border-radius: 8px;
}

.cancel-button {
    background-color: #ffffff;
    color: #395cbe;
    border: 1px solid #395cbe;
}

.buy-currency-box-display {
    padding: 2.5rem !important;
    border-radius: 50px !important;
    background-color: grey !important;
}

.buy-copy-button {
    padding: 15px;
    border-radius: 50px;
    background-color: #395cbe !important;
    border-color: white !important;
    margin-left: 15px !important;
}

.modal-contribution-address {
    font-size: 18px !important;
    font-weight: 600 !important;
}

.my-account-header-block {
    min-height: 240px !important;
}

.two-fa-code-input {
    padding: 0.375rem 0.75rem;
    font-size: 12px;
    line-height: 16px;
    color: #333333;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    width: 250px !important;
    display: inline-block !important;
    margin: 10px 15px !important;
}

.receiving-eth-address {
    border-radius: 30px !important;
    padding: 8px !important;
    border: none !important;
    color: grey !important;
    font-size: 14px !important;
}

.kyc-file-upload-container {
    width: 100% !important;
    display: table !important;
    cursor: pointer !important;
    height: 8rem !important;
    border: 1px white dashed;
}

.kyc-multifile-upload-container {
    width: 100% !important;
    display: table !important;
    cursor: pointer !important;
    height: auto !important;
    border: 1px white dashed;
    min-height: 8rem !important;
}

.upload-text-display {
    display: table-cell !important;
    vertical-align: middle !important;
}

.width-auto {
    width: auto !important;
    display: inline !important;
}

.display-inline {
    display: inline !important;
}

.styles_modal__gNwvD {
    max-width: 800px;
    position: relative;
    padding: 1.2rem;
    background: #ffffff;
    background-clip: padding-box;
    box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
    margin: auto;
}

.onboarding-modal .onboarding-slide .onboarding-content {
    padding: 24px !important;
}

.progressbar {
    display: flex;
    justify-content: center;
    padding-left: 0px;
    width: 95%;
    margin: auto;
    counter-reset: step !important;
}

.progressbar li {
    list-style: none !important;
    display: inline-block !important;
    width: 25% !important;
    position: relative !important;
    text-align: center !important;
    font-size: 14px;
}

.progressbar li:before {
    content: counter(step) !important;
    counter-increment: step !important;
    width: 24px !important;
    height: 24px !important;
    border: 1px solid #395cbe !important;
    border-radius: 100% !important;
    display: block !important;
    text-align: center !important;
    margin: 0 auto 5px auto !important;
    background-color: #fff !important;
    color: #395cbe;
    line-height: 22px;
}

.progressbar li:after {
    content: "" !important;
    position: absolute !important;
    width: 100% !important;
    height: 1px !important;
    background-color: #395cbe !important;
    top: 10px !important;
    left: -50% !important;
    z-index: 0 !important;
}

.progressbar li:first-child:after {
    content: none !important;
}

.progressbar li.active {
    color: #395cbe !important;
}

.progressbar li.active:before {
    border-color: white !important;
    color: white !important;
    background-color: #395cbe !important;
}

.progressbar li.active+li:after {
    background-color: #395cbe !important;
    z-index: 1 !important;
}

.filter-element-wrapper {
    padding-bottom: 0rem !important;
}

.filter-element-wrapper .form-control {
    width: 20rem !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.dashboard-header {
    font-size: 14px !important;
    margin: 6px 0px;
}

.color-red {
    color: red !important;
}

.dashboard-statistics-container:not(:last-child),
.user-statistics-container {
    border-right: 1px solid white !important;
}

.barrel-display {
    justify-content: center !important;
    display: inline-flex !important;
    padding: 6px 12px !important;
    color: #333333;
    background: #ebebeb;
    border-radius: 8px;
    margin: 6px 0px;
    font-size: 14px;
}

.bg-white {
    background: white !important;
}

.dashboard-my-investment-list td {
    padding: 0.25rem !important;
}

.el-tablo .value {
    font-size: 1rem !important;
}

.dashboard-my-investment-list.table thead th {
    border-bottom: none !important;
    white-space: nowrap !important;
}

.text-underline {
    text-decoration: underline !important;
}

.font-400 {
    font-weight: 400;
}

.text-small {
    font-size: 12px !important;
}

.text-default {
    font-size: 14px !important;
}

.text-large {
    font-size: 16px !important;
}

.text-xl {
    font-size: 20px;
}

.text-default-color {
    color: #333333;
}

.text-margin {
    margin: 6px 0px;
}

.table-striped tbody tr {
    font-size: 14px;
}

.default-border-btn {
    background-color: #ffffff;
    color: #395cbe;
    border: 1px solid #395cbe;
    border-radius: 8px;
}

.kyc-content {
    margin-top: 4rem;
    height: 60vh;
}

.default-btn {
    background-color: #395cbe;
    color: #ffffff;
    border: 1px solid #395cbe;
    border-radius: 8px;
    /* padding: 10px 15px; */
}

.default-btn:hover {
    color: #ffffff;
}

.flex-header {
    display: flex !important;
    justify-content: space-between;
    flex-direction: row;
}

.custom-tooltip {
    display: inline;
    position: relative;
}

.custom-tooltip:hover:after {
    background: #000;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    bottom: 26px;
    color: #fff;
    content: attr(tooltip-title);
    left: 0%;
    padding: 0.25rem 0.5rem;
    position: absolute;
    z-index: 98;
    width: 150px;
    overflow-wrap: break-word;
}

.custom-tooltip:hover:before {
    border: solid;
    border-color: #333 transparent;
    border-width: 6px 6px 0 6px;
    bottom: 20px;
    content: "";
    left: 50%;
    position: absolute;
    z-index: 99;
}

.custom-tooltip.tooltip-right:hover:after {
    right: 20% !important;
    left: inherit !important;
}

.custom-tooltip.tooltip-right:hover:before {
    right: 50% !important;
    left: inherit !important;
}

.display-inherit {
    display: inherit !important;
}

.white-background {
    background-color: white !important;
}

.xpr-chart-header {
    color: #333333 !important;
    font-size: 20px !important;
}

.card-inner-padding {
    padding: 24px;
}

.qrcode-image-container img {
    padding-bottom: 20px !important;
}

.document-type-header {
    color: #395cbe !important;
}

.filepond--root {
    min-height: 5rem !important;
    border: 2px dotted #ebebeb !important;
    margin-bottom: 0 !important;
}

.filepond--panel-root {
    background-color: transparent !important;
}

.filepond--drop-label {
    color: #395cbe !important;
    top: 20% !important;
    font-size: 12px !important;
    line-height: 16px !important;
}

.filepond--file-action-button.filepond--action-process-item {
    display: none !important;
}

.standardColor {
    color: #395cbe !important
}

.split-radio-container label {
    min-width: 35% !important;
}

.understanding2-split-radio-container label {
    min-width: 33% !important;
}

.wealth-split-radio-container label {
    min-width: 25% !important;
    margin-top: 5px !important;
}

.politically-exposed-radio-container label {
    min-width: 6% !important;
    margin-top: 5px !important;
}

.understanding-split-radio-container label {
    min-width: 20% !important;
    margin-top: 5px !important;
}

.radio-half-width-container label {
    min-width: 50% !important;
    margin-top: 5px !important;
}

.financial-products-invested label {
    min-width: 25% !important;
    margin-top: 5px !important;
}

.financial-information-textarea {
    background-color: transparent !important;
    color: white !important;
    resize: none !important;
}

.signature-pad-container {
    border: 2px dotted white !important;
}

.clear-sign-action {
    font-size: 0.8rem !important;
}

.padding-10 {
    padding: 10px;
}

.terms-container {
    padding: 40px !important;
    color: white !important;
    text-align: justify;
}

.cntry__menu-list {
    max-height: 10rem !important;
}

.identification-data-container,
.identification-data-container h3 {
    font-size: 16px !important;
}

.identification-data-container .row {
    margin-bottom: 10px !important;
}

.signature-container-img {
    max-width: 100% !important;
    width: 100% !important;
}

.user-dashboard-content {
    background-color: #fafafa !important;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
    overflow-x: hidden;
}

.user-dashboard-menu {
    background-color: white !important;
    position: fixed !important;
    left: 0 !important;
    bottom: 0 !important;
    z-index: 99 !important;
    width: 10%;
    top: 91px !important;
}

.user-dashboard-menu li span {
    color: #395cbe !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    opacity: 1 !important;
}

.header-fixed-menu {
    position: fixed !important;
    width: 100% !important;
    top: 0 !important;
    z-index: 100 !important;
}

.menu_active {
    background-color: rgba(0, 0, 0, 0.08);
}

.center-content {
    padding-top: 91px !important;
}

.user-dashboard-content {
    /* margin-left: 14% !important; */
    position: relative !important;
}

.supplier-size {
    font-size: 12px !important;
}

.alert-dark-blue {
    color: #333333 !important;
    background-color: #ffffff !important;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

.worth-highlighter {
    font-size: 14px !important;
    color: #333333 !important;
    margin: 6px 0px;
}

.card-padding {
    padding: 22px 30px;
}

.card-horizontal-padding {
    padding: 0 24px;
}

.user-dashboard-content {
    width: 80% !important;
    min-height: 100vh !important;
}

.content-box {
    padding-bottom: 0 !important;
    width: 100% !important;
}

.progressbar li:after {
    width: calc(100% - 24px) !important;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #395cbe;
}

.buyXprNotes {
    padding: 40px !important;
}

.buyXprNotes ol {
    padding-inline-start: 15px;
}

.instructon-modal-container OL {
    counter-reset: item;
}

.instructon-modal-container LI {
    display: block;
}


/* .instructon-modal-container LI:before {
  content: counters(item, ".") " ";
  counter-increment: item;
} */

.clearNumber {
    counter-reset: list !important;
}

.clearNumber .incrementNo li {
    list-style: none !important;
    position: relative !important;
}

.clearNumber li::before {
    counter-increment: list !important;
    content: "(" counter(list, lower-alpha) ") " !important;
}

.clearAlpha {
    counter-reset: list !important;
}

.clearAlpha li {
    list-style: none !important;
    position: relative !important;
}

.clearAlpha li::before {
    counter-increment: list !important;
    content: "(" counter(list, lower-roman) ") " !important;
}

.clearNumbers {
    counter-reset: number !important;
}

.clearNumbers li::before {
    counter-increment: number !important;
    content: " " counter(number) ". " !important;
}

.react-confirm-alert-button-group {
    justify-content: flex-end !important;
}

.react-confirm-alert-button-group button:first-child {
    background-color: #ffffff;
    color: #395cbe;
    border-radius: 8px;
    border: 1px solid #395cbe;
}

.react-confirm-alert-button-group button:last-child {
    background-color: #395cbe;
    color: #ffffff;
    border-radius: 8px;
    border: 1px solid #395cbe;
}

.underline {
    text-decoration: underline !important;
}

.instructionCheck {
    margin-left: 1rem !important;
    display: inline !important;
    margin-bottom: 1rem !important;
}

.declarationCheck {
    float: left !important;
}

.declarationLabel {
    display: block !important;
    margin-left: 40px !important;
}

.my-account-error-msg {
    font-size: 16px !important;
    border: 1px solid red;
}

.my-account-error-msg a {
    color: white !important;
    text-decoration: underline !important;
}

.myaccount-error-icon {
    color: red !important;
    font-size: 20px !important;
}

.assumption-block {
    color: black !important;
}

.listing-text-input {
    display: inline-block;
    border: 1px solid white;
    border-radius: 50px;
}

.listing-text-input label {
    padding-left: 10px;
    vertical-align: center;
    text-align: center;
    color: white;
}

.listing-text-input .form-control {
    width: 125px;
    border-radius: 50px;
}

.cusor-pointer {
    cursor: pointer;
}

.listing_file {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.myaccount-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.upload-button {
    background: transparent;
    border-radius: 50px;
    border: 1px solid #ced2d6;
    color: black;
    cursor: pointer;
}

.button-center {
    display: flex;
    justify-content: flex-end;
}

.text-right .margin-right {
    margin-right: 10px !important;
}

.wealth-split-radio-container span {
    margin-left: 6px !important;
}

.permian-button {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    border: none;
    border-radius: 8px;
    padding: 6px 18px;
    margin-right: 0px !important;
    background-color: #395cbe !important;
}

.button-container {
    display: flex;
    padding: 2px 5px !important;
}

.file-upload-border:nth-child(odd) {
    border-right: none;
}

.filter-blur {
    filter: blur(3px);
}

@media (max-width: 300px) {
    .upload-button {
        padding: 1px !important;
    }
}

@media (max-width: 991px) {
    .file-upload-border:nth-child(odd) {
        border-right: none !important;
    }
}

@media screen and (min-width: 480px) and (max-width: 991px) {
    .dashboard-statistics-container:nth-child(even),
    .user-statistics-container {
        border-right: none !important;
    }
}

.file-upload-border:last-child {
    padding-bottom: 0.5rem !important;
}

.listing-files-container i.filesuccess {
    border: 1px solid #ced2d6;
    padding: 3px;
    height: 24px;
    border-radius: 50px;
    background-color: #07bc0c;
    color: white;
    width: 24px;
    text-align: center;
    /* font-size: 14px; */
}

.listing-files-container i.fileerror {
    border: 1px solid #ced2d6;
    padding: 3px;
    height: 24px;
    border-radius: 50px;
    background: red;
    color: white;
    width: 24px;
    text-align: center;
}

.listing-location {
    border-radius: 50px;
    border: 1px solid #ced2d6;
    width: 60%;
    height: 32px;
    float: left;
}

.mark-on-map {
    background: transparent;
    color: black;
    margin-bottom: 10px;
    border: none !important;
    font-size: 14px !important;
    text-decoration: underline !important;
}

.payment-methods-container span {
    padding-left: 5px;
}

.payment-methods-container label:not(:first-child) {
    padding-left: 10px;
}

.listing-modal-container,
.listing-modal-container .modal-lg {
    max-width: 100% !important;
}

.location-autosuggest-container input {
    margin-bottom: 0px !important;
    border-radius: 8px !important;
    border: 1px solid #ebebeb !important;
    width: 100% !important;
    height: 32px !important;
    float: left !important;
    color: #395cbe !important;
}

.twofa-submit-button {
    background: #ffffff;
    border: 1px solid #395cbe;
    border-radius: 8px;
    color: #395cbe;
}

.twofa-submit-button:hover {
    background: #ffffff;
    color: #395cbe;
}

.listing-label {
    padding-right: 0.5rem !important;
    font-size: 14px;
}

.unit-selector {
    padding: 0 5px !important;
    font-size: 16px;
}

.submit-listing-container :focus {
    outline: none !important;
}

.average-listing-input {
    width: auto !important;
    border-radius: 8px;
}

.progressreportadd .filepond--drop-label {
    color: #395cbe !important;
}

.progressreportadd .filepond--root {
    border: 2px solid #dde2ec !important;
}

.progressreportadd .form-buttons-w {
    margin-top: 0 !important;
    padding-top: 0 !important;
    border-top: none !important;
}

.progressreportadd .element-wrapper {
    padding-bottom: 0 !important;
}

.colorwhite {
    color: white !important;
}

.listing-files-container.files-container {
    overflow: hidden !important;
}

.file-upload-border .account-button {
    margin-top: -1px;
}

.update-payment-method-input {
    width: 90% !important;
    padding: 0px 24px 0px 12px;
    height: 30px !important;
    border-radius: 8px;
    opacity: 1 !important;
    border: 1px solid #ebebeb !important;
    font-size: 12px;
    margin-top: -2px;
}

.update-payment-method-input:focus {
    outline: none !important;
}

.vertical_center {
    margin: auto 0;
}

.instructon-modal-container {
    padding: 0 !important;
    min-width: 30%;
    width: 40%;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 5%);
    border-radius: 8px;
}

.modal-content form {
    min-width: 3em;
}

.progress-category-select2 ::placeholder,
.progressreport__single-value,
.progressreport__placeholder,
.progressreport__indicators svg {
    color: #495057 !important;
}

body.page-loaded .css3-spinner {
    display: none !important;
}

.top-bar.custom-top-bar {
    min-height: 90px !important;
}

#chaparral_ticker {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2rem !important;
    border-radius: 5px !important;
}

.c-ticker {
    background-color: rgba(0, 0, 0, 0.7);
}

#chaparral_ticker .stock_ticker_data {
    right: 0xp !important;
    width: 180px;
    padding-top: 7px;
    padding-bottom: 7px;
    color: #fff;
    font-weight: normal;
    font-size: 14px !important;
    text-align: left;
    box-shadow: 0 1px 2px #fff, 0 -1px 1px #666, inset 0 -1px 1px rgba(0, 0, 0, 0.5), inset 0 1px 1px rgba(255, 255, 255, 0.8);
    border-radius: 5px !important;
}

.c-ticker .chs_exchange {
    margin-bottom: -0.4em;
    padding-left: 15px;
    color: #999;
}

.c-ticker .chs_symbol {
    padding-left: 15px;
    font-size: 18px;
}

.c-ticker .chs_symbol,
.c-ticker .chs_pricing {
    display: inline-block;
}

#chaparral_ticker .stock_ticker_data {
    right: 0xp !important;
    width: 180px;
    padding-top: 10px;
    color: #fff;
    font-weight: normal;
    font-size: 14px !important;
    text-align: left;
}

.c-ticker .chs_pricing {
    font-size: 18px;
}

.c-ticker .chs_symbol,
.c-ticker .chs_pricing {
    display: inline-block;
}

#chaparral_ticker .stock_ticker_data .chs_pricing .ticker__green {
    color: #9f0 !important;
}

.mb-40 {
    margin-bottom: 40px;
}

.centerofscreen {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.auth-box-w.signupwidth {
    /* margin: 4em 0; */
    min-width: 50% !important;
}

.basepage-footer-container {
    position: fixed !important;
    bottom: 5px !important;
    left: 0px !important;
    right: 0px !important;
    margin-bottom: 0px !important;
}

.footer-container {
    clear: both;
}

.footer-container .footer {
    height: inherit !important;
    line-height: inherit !important;
    margin-bottom: 10px !important;
}

.footer {
    position: relative;
    width: 100%;
}

.info-bottom-link {
    color: white !important;
    float: right !important;
    padding-right: 15px !important;
    font-size: 18px !important;
}

.width-35 {
    width: 35% !important;
}

.width-90 {
    width: 90% !important;
}

.width-60 {
    width: 60% !important;
}

.signuppt {
    padding-top: 2.5rem !important;
}

.signup-button {
    margin-bottom: 0rem !important;
}

.suggestion-item:nth-child(1) {
    padding-top: 10px !important;
    margin-top: 10px !important;
}

.suggestion-item,
.suggestion-item--active {
    padding: 5px !important;
}

.mt-5 {
    margin-top: 5px !important;
    padding-top: 5px !important;
}

.autocomplete-dropdown-container {
    margin-top: 5px;
    padding-top: 5px;
}

.location-search-input {
    padding-left: 15px;
}

.emptyspace {
    margin-top: 10px !important;
}

.listing-modal-container {
    margin: auto !important;
}

.mapmarker-location .onboarding-modal .onboarding-slide .onboarding-content {
    padding: 0px !important;
}

.mapmarker-location .onboarding-modal,
.mapmarker-location .onboarding-content {
    width: 600px !important;
}

.duediligence-text p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
}

.color-dark {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
}

.mapmarker-location .closeButton,
.mapmarker-location .closeIcon {
    display: none !important;
}

#listingmapclose {
    display: none !important;
}

.mt-15-percent {
    margin-top: 15% !important;
}

.autosuggest-container {
    display: inline-block;
    width: 100%;
}

.terms_conditions_wrapper_container .basepage-footer-container {
    position: inherit !important;
}

.content-wrapper {
    min-height: 95%;
    margin-bottom: -50px;
}

.pt50 {
    padding-top: 50px !important;
}

.min-width-40 {
    min-width: 40% !important;
}

.min-height-90vh {
    min-height: 90vh !important;
}

.min-height-80vh {
    min-height: 83.6vh !important;
}

.terms-margin {
    margin-top: 3rem !important;
}

.terms-page-container .color-white::after {
    border-bottom: 1px solid white !important;
}


/* .buy-xpr-currencey-select {
  border: 1px solid silver;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-repeat: no-repeat;
  background-position: 95% 42%;
  mask: url(/static/media/chevron-down-solid.b0765aef.svg) no-repeat 100% 100%;
  background-color: white;
  transform: scale(0.4);
  position: relative;
  left: 15px;
} */

.menu-w .logged-user-menu ul {
    padding: 0px 15px !important;
    padding-bottom: 10px !important;
}

.logged-user-name {
    color: #333333;
}

.padding-initial {
    padding: initial !important;
}

.submenuleftpadding {
    padding-left: 1.4rem !important;
}

.menu-padding-set {
    padding-left: 1.5rem !important;
}

.display-block {
    display: block !important;
}

.token-picker-widget {
    padding: 0 2rem 0 0 !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.logged-user-w .logged-user-role {
    color: black;
    font-weight: bold !important;
}

.emd-name-display {
    text-transform: inherit !important;
}

.investor-dealer-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menu-w.menu-layout-compact .logged-user-w.avatar-inline .logged-user-i .logged-user-info-w.emdcontainer {
    padding-left: 5px !important;
}

.disclaimer-text {
    margin: 30px 0 15px 15px;
    color: black;
    font-size: 16px;
}

.login-container,
.register-container {
    margin-top: 40px;
}

.centerofthescreen {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.min-width-50 {
    min-width: 45% !important;
}

.form-check {
    padding-left: 10px !important;
}

.full-width-container {
    max-width: 100% !important;
}

.dealer-image {
    min-height: 150px !important;
    min-width: 150px;
    max-height: 150px;
    max-width: 150px;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}

.select-emd-button {
    padding: 2px 8px !important;
}

.emd-box {
    height: 100% !important;
    margin-bottom: 20px !important;
}

.post-box:hover {
    transform: inherit !important;
}

.post-title {
    line-height: 24px;
}

.color-black {
    color: black !important;
}

.emd-selected {
    border: 1px solid #395cbe;
}

.address-break {
    word-break: break-all;
}

.content-space-between {
    display: flex;
    justify-content: space-between;
}

.btn-campaigns {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.margintop-10 {
    margin-top: 10px;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 100%;
        margin: 1.75rem auto;
    }
}

@media (min-width: 577px) {
    .modal-dialog {
        margin: 0 !important;
    }
    .two-factor-desc {
        margin-left: 1em;
    }
}

@media (min-width: 1025px) {
    .user-dashboard-content {
        margin-left: 16% !important;
    }
}

@media (min-width: 1200px) {
    .mgtp-xl-3 {
        margin-top: 1rem;
    }
    .mgtp-xl-4 {
        margin-top: 1.5rem;
    }
}

.menu-mobile.color-scheme-dark {
    /* background-image: linear-gradient(to bottom, #3D4D75 0%, #31395B 100%);
  background-repeat: repeat-x; */
    background-image: linear-gradient(to bottom, #1c4cc3 0%, #1c2e7b 300%);
    background-repeat: repeat-x;
    color: rgba(255, 255, 255, 0.9);
}

.menu-container {
    background-color: #ffffff;
}

.my-account-details {
    overflow-wrap: break-word;
}

@media screen and (min-width: 577px) and (max-width: 652px) {
    .twofa-submit-button {
        text-align: center;
    }
    .two-factor {
        flex-wrap: wrap !important;
    }
}

@media screen and (min-width: 577px) and (max-width: 777px) {
    .mgtp-sm-4 {
        margin-top: 1.5rem;
    }
    .mgtp-sm-3 {
        margin-top: 1rem;
    }
}

@media (max-width: 400px) {
    /* .account-button {
    padding: 6px 8px !important;
    border-radius: 50px;
    margin-right: -22px !important;
    background-color: #395cbe !important;
    border: 1px solid white !important;
  } */
    .update-btn {
        margin-left: 13px !important;
    }
    .update-payment-method-input {
        width: 100% !important;
        padding-left: 15px !important;
        height: 30px !important;
        border-radius: 50px 0px 0px 50px;
        opacity: 1 !important;
        border: white !important;
        font-size: 16px;
        font-weight: 500;
        padding-right: 10px;
        margin-left: 8px !important;
    }
}

@media screen and (min-width: 401px) and (max-width: 767px) {
    .update-btn {
        margin-left: 10px !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .menu-mobile .mm-logo-buttons-w .mm-logo img {
        display: none;
    }
    .checkbox-label-lg-margin {
        margin-left: -30px;
    }
}

@media screen and (min-width: 1025px) {
    .checkbox-label-lg-margin {
        margin-left: -50px;
    }
}

@media screen and (min-width: 100px) and (max-width: 767px) {
    .user-dashboard-content {
        width: 100% !important;
    }
    .menu-mobile .mm-logo-buttons-w .mm-logo img {
        width: auto !important;
    }
    .file-upload-border:nth-child(even) {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .file-upload-border:nth-child(odd) {
        border-right: none;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .file-upload-border:last-child {
        padding-bottom: 0.5rem !important;
    }
    .manage-header {
        flex-direction: column;
        row-gap: 5px;
    }
}

@media screen and (min-width: 180px) and (max-width: 576px) {
    /* .my-account-details:not(:last-child) {
    margin-bottom: 0px !important;
  } */
    .two-factor {
        flex-wrap: wrap !important;
    }
    .dashboard-statistics-container:not(:last-child),
    .user-statistics-container {
        border-right: none !important;
    }
    .xs-margin {
        margin: 10px;
    }
    .mgtp-xs-4 {
        margin-top: 1.5rem;
    }
    .mgtp-xs-3 {
        margin-top: 1rem;
    }
}


/* @media screen and (min-width: 180px) and (max-width: 439px) {
  .icheckbox_square-blue,
  .iradio_square-blue {
    width: 30px !important;
  }
} */

@media screen and (min-width: 100px) and (max-width: 480px) {
    .mgtp-xs-3 {
        margin-top: 10px;
    }
    .flex-header {
        flex-direction: column;
    }
    .instructon-modal-container {
        width: 90%;
    }
    /* .kyc-agree-container {
    display: flex;
  } */
    .form-check {
        padding-left: 0px !important;
    }
    .width-100 {
        width: 100% !important;
    }
    .pl-none {
        padding-left: 0px !important;
    }
    .text-sm {
        font-size: 16px;
    }
    .auth-box-w.wider,
    .auth-box-w.login-box {
        margin-top: 2em;
        border-radius: 0px !important;
    }
    .center-content {
        padding-top: 0px !important;
    }
    .auth-box-w form,
    .auth-box-w.login-box form {
        padding: 20px 20px 40px 20px !important;
        width: 351px;
    }
    .auth-box-w {
        background-color: transparent !important;
    }
    .auth-box-w.signupwidth {
        margin-top: 2em;
        min-width: 50% !important;
    }
    .auth-box-w .logo-w {
        padding: 20px 0 0 0 !important;
        width: 351px;
    }
    .footer,
    .base-footer .footer {
        line-height: inherit !important;
        margin-top: 30px !important;
        width: auto;
        margin: auto;
    }
    .two-factor {
        flex-wrap: wrap !important;
    }
    .two-factor-btn {
        flex-wrap: wrap !important;
    }
    .confirmPasswordContainer {
        margin-top: 1rem !important;
    }
    .terms-block,
    .info-bottom-link {
        font-size: 14px !important;
    }
    .copyright-text {
        width: 100%;
        margin-top: 3em !important;
        font-size: 14px !important;
        padding-bottom: 10px !important;
    }
    .main-content-container {
        margin-top: 0px !important;
    }
    .custom-form .input-group-text {
        top: 10% !important;
        padding: 10px !important;
    }
    .custom-form .form-control {
        padding: 12px !important;
    }
    .custom-form .input-group input {
        text-indent: 2.75rem !important;
    }
    .wrapper {
        margin-top: 10% !important;
    }
    .top-bar:not(.admin-top-bar) .top-menu-controls {
        padding: 10px 0 !important;
    }
    .split-radio-container label,
    .wealth-split-radio-container label,
    .understanding-split-radio-container label {
        min-width: 100% !important;
    }
    .main-content-container {
        padding-top: 0 !important;
    }
    .menu-container {
        background-color: #ffffff;
    }
    .customLogo {
        margin-left: 0 !important;
    }
    .menu-mobile .mm-logo-buttons-w .mm-logo img {
        width: auto !important;
    }
    .dashboard-statistics-container:not(:last-child),
    .user-statistics-container {
        border-right: none !important;
    }
    .buy-xpr-header-block {
        font-size: 14px !important;
    }
    /* .dashboard-statistics-container {
    margin: 10px 0 !important;
  } */
    .my-account-details:not(:last-child) {
        margin-bottom: 20px !important;
    }
    .my-account-container h5 span {
        font-weight: 0.8rem !important;
    }
    .my-account-container h5 span.pull-right {
        margin-top: 0.4rem !important;
    }
    .two-factor-heading-text,
    .twofa-submit-button {
        margin-top: 15px !important;
    }
    .two-factor-heading-header {
        margin-top: 40px !important;
    }
    .buy-xpr-currencey-select {
        width: 40% !important;
        height: 30px !important;
        border-radius: 0 8px 8px 0;
        background-color: #ffffff;
        border-color: #b5b2b2 !important;
        padding: 0 2px !important;
    }
    .listing-reserves-input,
    .tokens-xpr-input {
        width: 90% !important;
        padding-left: 5px !important;
        font-size: 14px !important;
        padding-right: 5px !important;
    }
    .buy-xpr-block {
        padding: 0.8rem !important;
    }
    .buy-xpr-container {
        font-size: 12px !important;
    }
    .converter-sign {
        padding-left: 0.5rem !important;
    }
    .xpr-tokens-label {
        padding-left: 0.5rem !important;
    }
    .buy-xpr-label {
        padding-right: 0.5rem !important;
        margin-top: 0.3rem !important;
    }
    .token-symbol-input {
        width: 10% !important;
    }
    .eth-contribution-input {
        width: 100% !important;
        margin-top: 10px !important;
    }
    .buy-xpr-buttons {
        padding: 5px 15px !important;
        margin-top: 10px !important;
        margin-left: 0 !important;
    }
    .buy-online-payment-buttons {
        width: auto !important;
        margin-top: 10px !important;
    }
    .user-dashboard-content {
        min-height: 100vh !important;
    }
    .wallet-header {
        font-size: 14px !important;
    }
    .buy-xpr-container h6 {
        font-weight: 200 !important;
    }
    .identification-header {
        font-size: 18px !important;
    }
    .kyc-container.mt-4 {
        margin-top: 15px !important;
    }
    .identification-block .permian-button {
        padding: 15px 45px !important;
    }
    .country-top-margin {
        margin-top: 15px !important;
    }
    .our-service-container .iradio_square-blue {
        margin-left: 0px !important;
    }
    .info-bottom-link {
        font-size: 14px !important;
    }
    .auth-box-w {
        box-shadow: none !important;
    }
    /* .auth-box-w.centerofscreen {
    min-width: 70% !important;
  } */
    .mapmarker-location .onboarding-modal,
    .mapmarker-location .onboarding-content {
        width: 300px !important;
    }
    .styles_closeButton__20ID4 {
        top: 6px !important;
        right: 5px !important;
    }
    .progressbar li {
        font-size: 12px !important;
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    .exchange-content-container {
        padding: 0% !important;
    }
    .my-recent-orders-container {
        overflow-x: scroll !important;
    }
    .exchange-input2 {
        width: 100% !important;
    }
    .user-dashboard-content {
        margin-left: 0px !important;
    }
    .mm-logo-buttons-w {
        display: none !important;
    }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
    .menu-mobile .mm-logo-buttons-w {
        display: flex !important;
    }
    .user-dashboard-content {
        margin-left: 0px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .layout-w {
        background-color: white;
    }
    .user-dashboard-content {
        margin-left: 66px !important;
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    .exchange-content-container {
        padding: 0% !important;
    }
    .exchange-input2 {
        width: 100% !important;
    }
    .my-recent-orders-container {
        overflow-x: scroll !important;
    }
    .mm-logo-buttons-w {
        display: none !important;
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 575px) {
    .confirmPasswordContainer {
        margin-top: 16px;
    }
    /* .kyc-agree-container {
    display: flex;
  } */
    .width-100 {
        width: 100% !important;
    }
    .flex-header-md {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }
}

@media screen and (min-width: 180px) and (max-width: 961px) {
    .signuppage-info-bottom-link {
        width: 100%;
        text-align: center;
    }
    .signin-info-bottom-link {
        text-align: center !important;
    }
    .width-100 {
        width: 100% !important;
    }
    .btn-center-group {
        display: flex;
        justify-content: center;
    }
}

@media screen and (min-width: 481px) {
    .pull-left {
        padding-left: 8px;
    }
}

@media only screen and (min-device-width: 576px) and (max-device-width: 768px) {
    .cstmField {
        flex: unset !important;
        max-width: 100% !important;
    }
}

@media only screen and (max-device-width: 768px) {
    .custom-top-bar {
        display: none !important;
    }
    .center-content {
        padding-top: 0px !important;
    }
}

@supports (-webkit-touch-callout: none) {
    .buy-xpr-currencey-select {
        -moz-appearance: none !important;
        background: transparent url(../img/drop-down-icon.svg?v3) right center no-repeat !important;
    }
    /* CSS specific to iOS devices */
}

@supports not (-webkit-touch-callout: none) {
    /* .buy-xpr-currencey-select

  {
    -moz-appearance:none !important;
    background: transparent url(../img/drop-down-icon.svg?v3) right center no-repeat !important;
  } */
    /* CSS for other than iOS devices */
}

@media screen and (max-device-width: 575px) {
    .select-icon SELECT {
        appearance: none;
        /* -moz-appearance: none !important; */
        background: transparent url(../img/down-arrow.svg?v3) right center no-repeat !important;
    }
    .listing-reserves-input {
        width: 100% !important;
    }
    .kycNextBtn {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 440px) {
    .react-confirm-alert-body {
        width: 95% !important;
        margin: auto !important;
    }
    .react-confirm-alert-body>h1 {
        font-size: 30px !important;
    }
}

@media screen and (max-width: 417px) {
    .manage-button button:last-of-type {
        margin-top: 10px;
        position: relative;
        left: -15px;
        margin-left: -20px;
        padding: 6px 15px;
    }
}

@media screen and (max-width: 330px) {
    .manage-button button:nth-child(2) {
        position: relative;
        left: 3px;
        margin-left: 2px !important;
    }
}

@media screen and (min-device-width: 579px) and (max-device-width: 1152px) {
    .beneficialStepper {
        top: 20px;
    }
}

@media screen and (max-device-width: 578px) {
    .beneficialStepper {
        top: 40px;
    }
}

@media screen and (max-device-width: 635px) {
    .relationshipStepper {
        top: 20px;
    }
    .custom-element-box .form-header {
        font-size: 16px;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 815px) {
    .filter-element-wrapper .form-control {
        width: 18em !important;
    }
    .instructon-modal-container {
        width: 65%;
    }
}

@media screen and (min-device-width: 816px) and (max-device-width: 991px) {
    .instructon-modal-container {
        width: 60%;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
    .mgtp-md-4 {
        margin-top: 1.5rem;
    }
    .mgtp-md-3 {
        margin-top: 1rem;
    }
}

@media screen and (max-device-width: 717px) {
    .filter-element-wrapper .form-control {
        width: 100% !important;
    }
    .shareholder-btn-group {
        margin-top: 10px !important;
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

@media screen and (max-device-width: 767px) {
    .forcast-submit {
        display: flex;
        justify-content: center;
    }
    .user-dashboard-content {
        width: 100% !important;
    }
    .mobile-text-default {
        font-size: 12px !important;
    }
    .kyc-agree-container label {
        font-size: 14px !important;
        margin-top: -5px;
    }
    .mgtp-sm-4 {
        margin-top: 1.5rem;
    }
    .mgtp-sm-3 {
        margin-top: 1rem;
    }
}

@media screen and (min-device-width: 1305px) and (max-device-width: 1480px) {
    .shareholder-btn-group {
        margin-top: 10px !important;
    }
}

@media only screen and (min-device-width: 576px) and (max-device-width: 992px) {
    .filter-type-option {
        margin-top: 10px;
    }
    .shareholder-btn-group {
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1200px) {
    .fix-width {
        max-width: 70% !important;
    }
    .font-small {
        font-size: 12px !important;
    }
    .mgtp-lg-3 {
        margin-top: 1rem;
    }
    .mgtp-lg-4 {
        margin-top: 1.5rem;
    }
}

@media (min-width: 992px) {
    .mgtp-lg-4 {
        margin-top: 1.5rem;
    }
}

@media only screen and (min-device-width: 1035px) and (max-width: 1135px) {
    .card-padding {
        height: 160px;
    }
}

@media only screen and (min-device-width: 1025px) and (max-device-width: 1191px) {
    .menu-w .logged-user-menu ul li a {
        padding: 10px 0px !important;
    }
    .menu-w .logged-user-menu ul li a i {
        margin-right: 10px;
        font-size: 15px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
    .margin-authorised {
        margin-bottom: 20px;
    }
}

@media only screen and (max-device-width: 390px) {
    .btn-campaigns {
        display: flex;
        justify-content: flex-start;
    }
    .campaigns-heading {
        display: unset !important;
    }
}

@media only screen and (min-device-width: 767px) {
    .menu-container {
        width: 0px;
    }
    .checkbox-lg-margin {
        margin-top: 8px;
    }
}

@media only screen and (min-device-width: 480px) and (max-device-width: 510px) {
    .icheckbox_square-blue {
        background-size: 250px 24px !important;
        width: 26px !important;
    }
}

@media screen and (min-device-width: 646px) and (max-device-width: 767px) {
    .instructon-modal-container {
        width: 70%;
    }
}

@media only screen and (min-device-width: 480px) and (max-device-width: 645px) {
    .graph-inner-padding {
        padding: 24px 10px !important;
        font-size: 10px !important;
    }
    .instructon-modal-container {
        width: 80%;
    }
}

@media only screen and (min-device-width: 480px) and (max-device-width: 510px) {
    .icheckbox_square-blue {
        background-size: 250px 24px !important;
        width: 26px !important;
    }
}

@media only screen and (min-device-width: 432px) and (max-device-width: 480px) {
    .icheckbox_engagement_terms {
        background-size: 250px 24px !important;
        width: 29px !important;
        /* width: 26px!important; */
    }
}

@media only screen and (max-device-width: 768px) {
    #chartdiv {
        width: 610px !important;
    }
    .highchart {
        overflow: auto;
    }
}

@media only screen and (min-device-width: 390px) and (max-device-width: 432px) {
    .icheckbox_engagement_terms {
        background-size: 250px 24px !important;
        width: 34px !important;
    }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 390px) {
    .icheckbox_engagement_terms {
        background-size: 250px 24px !important;
        width: 39px !important;
    }
}

@media only screen and (max-device-width: 360px) {
    .icheckbox_engagement_terms {
        background-size: 250px 24px !important;
        width: 45px !important;
    }
}

@supports (-moz-appearance: none) {
    .buy-xpr-currencey-select {
        border: none;
        background-position: right center;
        background-color: #b5b2b2;
        -moz-appearance: none !important;
        background-repeat: no-repeat;
        background-image: url(../img/drop-down-icon.svg?v3) !important;
    }
    .select-icon SELECT {
        appearance: none !important;
        background: transparent url(../img/down-arrow.svg?v3) right center no-repeat !important;
    }
}