@charset "UTF-8";

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated-fast {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.bounceIn,
.animated.bounceOut {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}

.animated.flipOutX,
.animated.flipOutY {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}

@-webkit-keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

@-webkit-keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

@keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

.flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

@-webkit-keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}

@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes wobble {
  from {
    -webkit-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes wobble {
  from {
    -webkit-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
}

@-webkit-keyframes jello {
  from,
  11.1%,
  to {
    -webkit-transform: none;
    transform: none;
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

@keyframes jello {
  from,
  11.1%,
  to {
    -webkit-transform: none;
    transform: none;
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

.jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center;
}

@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

@-webkit-keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
}

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
}

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    -ms-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    -ms-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    /*-webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);*/
    -webkit-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    /*-webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);*/
    -webkit-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    /*-webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);*/
    -webkit-transform: translate3d(-50px, 0, 0);
    transform: translate3d(-50px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    /*-webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);*/
    -webkit-transform: translate3d(-50px, 0, 0);
    transform: translate3d(-50px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    /*-webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);*/
    -webkit-transform: translate3d(50px, 0, 0);
    transform: translate3d(50px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    /*-webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);*/
    -webkit-transform: translate3d(50px, 0, 0);
    transform: translate3d(50px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    visibility: hidden;
    /*-webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);*/
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
  }
  to {
    opacity: 1;
    /*visibility: visible;*/
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    visibility: hidden;
    /*-webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);*/
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
  }
  to {
    visibility: visible;
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUpMenu {
  from {
    opacity: 0;
    visibility: hidden;
    /*-webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);*/
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    visibility: visible;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUpMenu {
  from {
    opacity: 0;
    visibility: hidden;
    /*-webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);*/
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    visibility: visible;
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUpMenu {
  -webkit-animation-name: fadeInUpMenu;
  animation-name: fadeInUpMenu;
}

@-webkit-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
}

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig;
}

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig;
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
}

@-webkit-keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

@keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
}

@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
}

@-webkit-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
}

@-webkit-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

@keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

.flipOutX {
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@-webkit-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}

@keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}

.flipOutY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY;
}

@-webkit-keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
  }
  to {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
  }
  to {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedOut {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}

@keyframes lightSpeedOut {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}

.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

@-webkit-keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
}

@-webkit-keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
}

@-webkit-keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
}

@-webkit-keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
}

@-webkit-keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
}

@-webkit-keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}

@keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}

.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut;
}

@-webkit-keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}

@keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}

.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
}

@-webkit-keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}

@keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}

.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
}

@-webkit-keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}

.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
}

@-webkit-keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}

.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
}

@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes rollOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}

@keyframes rollOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}

.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut;
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

@-webkit-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown;
}

@-webkit-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft;
}

@-webkit-keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight;
}

@-webkit-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp;
}

@-webkit-keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}

.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
}

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown;
}

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}

.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft;
}

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}

.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight;
}

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}

@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

/* style.css */

.home-page-main-container.home-page-container {
  font-weight: 300;
  font-size: 16px;
  line-height: 2;
  color: black;
  background: whitesmoke;
}

.home-page-main-container .homepage {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.home-page-main-container .offcanvas #page {
  overflow: hidden;
  position: absolute;
}

.home-page-main-container .offcanvas #page:after {
  -webkit-transition: 2s;
  -o-transition: 2s;
  transition: 2s;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.7);
  content: "";
}

.home-page-main-container a {
  /* color: #4aca85; */
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.home-page-main-container a:hover,
.home-page-main-container a:active,
.home-page-main-container a:focus {
  /* color: #4aca85; */
  outline: none;
  text-decoration: none;
}

.home-page-main-container p {
  margin-bottom: 20px;
}

.home-page-main-container h1,
.home-page-main-container h2,
.home-page-main-container h3,
.home-page-main-container h4,
.home-page-main-container h5,
.home-page-main-container h6,
.home-page-main-container figure {
  color: #303133;
  font-family: "Nunito", Arial, sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  line-height: 1.3;
}

.home-page-main-container ::-webkit-selection {
  color: #fff;
  background: #375ebf;
}

.home-page-main-container ::-moz-selection {
  color: #fff;
  background: #375ebf;
}

.home-page-main-container ::selection {
  color: #fff;
  background: #375ebf;
}

.home-page-main-container .product-img,
.home-page-main-container .work-img,
.home-page-main-container .author-img,
.home-page-main-container .mobile-img,
.home-page-main-container .colorlib-video,
.home-page-main-container .blog-img,
.home-page-main-container .colorlib-about-img,
.home-page-main-container .services-img,
.home-page-main-container .staff-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}

.home-page-main-container .colorlib-nav,
.home-page-main-container .homecolorlibnav {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .home-page-main-container .colorlib-nav,
  .home-page-main-container .homecolorlibnav {
    margin: 0;
  }
}

.home-page-main-container .colorlib-nav .top-menu,
.home-page-main-container .homecolorlibnav .top-menu {
  padding: 30px 30px;
}

@media screen and (max-width: 768px) {
  .home-page-main-container .colorlib-nav .top-menu {
    padding: 28px 1em;
  }
}

.home-page-main-container .colorlib-nav #colorlib-logo,
.home-page-main-container .homecolorlibnav .homecolorliblogo {
  font-size: 20px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
}

.home-page-main-container .colorlib-nav #colorlib-logo a {
  position: relative;
  color: #fff;
}

.home-page-main-container .colorlib-nav #colorlib-logo a i {
  color: #fff;
  display: block;
  font-size: 60px;
  font-weight: normal;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .home-page-main-container .colorlib-nav .menu-1 {
    display: none;
  }
}

.home-page-main-container .colorlib-nav ul {
  padding: 0;
  margin: 0;
}

.home-page-main-container .colorlib-nav ul li {
  padding: 8px 0;
  margin: 0;
  list-style: none;
  display: inline;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
}

.home-page-main-container .colorlib-nav ul li a {
  position: relative;
  padding: 20px 12px;
  color: rgba(255, 255, 255, 0.9);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.home-page-main-container .colorlib-nav ul li a:hover {
  color: #fff;
}

.home-page-main-container .colorlib-nav ul li.has-dropdown {
  position: relative;
}

.home-page-main-container .colorlib-nav ul li.has-dropdown .dropdown {
  width: 140px;
  -webkit-box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
  z-index: 1002;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 40px;
  left: 0;
  text-align: left;
  background: #000;
  padding: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}

.home-page-main-container .colorlib-nav ul li.has-dropdown .dropdown:before {
  bottom: 100%;
  left: 40px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #000;
  border-width: 8px;
  margin-left: -8px;
}

.home-page-main-container .colorlib-nav ul li.has-dropdown .dropdown li {
  display: block;
  margin-bottom: 0px;
}

.home-page-main-container
  .colorlib-nav
  ul
  li.has-dropdown
  .dropdown
  li:last-child {
  margin-bottom: 0;
}

.home-page-main-container .colorlib-nav ul li.has-dropdown .dropdown li a {
  padding: 2px 0;
  display: block;
  color: #999999;
  line-height: 1.2;
  text-transform: none;
  font-size: 13px;
  letter-spacing: 0;
}

.home-page-main-container
  .colorlib-nav
  ul
  li.has-dropdown
  .dropdown
  li
  a:hover {
  color: #fff;
}

.home-page-main-container .colorlib-nav ul li.btn-cta a {
  color: #fff;
  background: #375ebf;
  padding: 10px 15px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  border-radius: 100px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.home-page-main-container .colorlib-nav ul li.btn-cta a:hover {
  -webkit-box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
  -ms-box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
  -o-box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
}

.home-page-main-container .colorlib-nav ul li.active > a {
  color: rgba(255, 255, 255, 0.5);
  position: relative;
}

.home-page-main-container .colorlib-nav ul li.active > a:after {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.home-page-main-container .colorlib-video {
  overflow: hidden;
  position: relative;
  height: 400px;
}

.home-page-main-container .colorlib-video a {
  z-index: 1001;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -45px;
  margin-left: -45px;
  width: 90px;
  height: 90px;
  display: table;
  text-align: center;
  background: #798eea;
  -webkit-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -ms-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -o-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.home-page-main-container .colorlib-video a i {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-size: 40px;
  color: #fff;
}

.home-page-main-container .colorlib-video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.home-page-main-container .colorlib-video:hover .overlay {
  background: rgba(0, 0, 0, 0.7);
}

.home-page-main-container .colorlib-video:hover a {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.home-page-main-container .video-hero {
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  z-index: 0;
}

@media screen and (max-width: 768px) {
  .home-page-main-container .video-hero {
    height: inherit;
    padding: 3em 0;
  }
}

.home-page-main-container .video-hero .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.95;
  background: #499bea;
  background: -moz-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: -webkit-gradient(
    left top,
    right bottom,
    color-stop(0%, #499bea),
    color-stop(100%, #798eea)
  );
  background: -webkit-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: -o-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: -ms-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: -webkit-linear-gradient(315deg, #499bea 0%, #798eea 100%);
  background: -o-linear-gradient(315deg, #499bea 0%, #798eea 100%);
  background: linear-gradient(135deg, #499bea 0%, #798eea 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#499bea', endColorstr='#798eea', GradientType=1);
  z-index: 1;
}

.home-page-main-container .video-hero .display-t {
  display: table;
  height: 700px;
  width: 100%;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .home-page-main-container .video-hero .display-t {
    height: 600px;
  }
}

.home-page-main-container .video-hero .display-t.display-t2 {
  height: 500px;
}

.home-page-main-container .video-hero .display-tc {
  display: table-cell;
  vertical-align: middle;
}

.home-page-main-container .video-hero .display-tc .holder {
  color: #fff;
  font-family: "Poppins", Arial, sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 7px;
}

.home-page-main-container .video-hero .display-tc .holder span {
  position: relative;
}

.home-page-main-container .video-hero .display-tc h2 {
  color: #fff;
  font-size: 60px;
  margin-bottom: 20px;
  font-weight: 300;
}

@media screen and (max-width: 768px) {
  .home-page-main-container .video-hero .display-tc h2 {
    font-size: 34px;
  }
}

.home-page-main-container .video-hero .display-tc p {
  font-family: "Nunito", Arial, sans-serif;
  color: #fff;
  font-weight: 200;
  font-size: 30px;
}

.home-page-main-container .video-hero .display-tc p.breadcrumbs {
  font-size: 14px;
}

.home-page-main-container .video-hero .display-tc p.breadcrumbs span {
  padding-right: 10px;
  color: rgba(255, 255, 255, 0.5);
}

.home-page-main-container .video-hero .display-tc p.breadcrumbs span a {
  color: rgba(255, 255, 255, 0.9);
}

.home-page-main-container .video-hero .display-tc .btn-custom {
  font-size: 16px;
}

.home-page-main-container .colorlib-about,
.home-page-main-container .colorlib-shop,
.home-page-main-container .colorlib-work,
.home-page-main-container .colorlib-work-featured,
.home-page-main-container .colorlib-intro,
.home-page-main-container #colorlib-subscribe,
.home-page-main-container .colorlib-pricing,
.home-page-main-container .colorlib-services,
.home-page-main-container #colorlib-contact,
.home-page-main-container .colorlib-blog {
  padding: 7em 0;
  clear: both;
}

.home-page-main-container .colorlib-bg-white {
  background: #fff;
  border: 1px solid #e6e6e6;
  border-left: none;
  border-right: none;
}

.home-page-main-container .colorlib-featured {
  padding-bottom: 3em;
  width: 100%;
}

.home-page-main-container .colorlib-featured .featured-wrap {
  margin-top: -7em;
}

.home-page-main-container .colorlib-featured .featured-entry img {
  -webkit-box-shadow: 0px 3px 19px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 19px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 19px -9px rgba(0, 0, 0, 0.75);
  margin-bottom: 20px;
}

.home-page-main-container
  .colorlib-featured
  .owl-theme
  .owl-dots
  .owl-dot
  span {
  background: #e6e6e6;
}

.home-page-main-container
  .colorlib-featured
  .owl-theme
  .owl-dots
  .owl-dot.active
  span {
  background: #cccccc;
}

.home-page-main-container .services {
  margin-bottom: 50px;
  position: relative;
  z-index: 0;
  padding: 0 1em;
}

@media screen and (max-width: 768px) {
  .home-page-main-container .services {
    margin-bottom: 3em;
  }
}

.home-page-main-container .services .icon {
  position: relative;
  margin-bottom: 30px;
  display: inline-block;
}

.home-page-main-container .services .icon i {
  font-size: 50px;
  color: #255a9f !important;
  z-index: 1;
}

.home-page-main-container .services .desc h3 {
  font-size: 26px;
  font-weight: 300;
}

.home-page-main-container .colorlib-intro .colorlib-heading {
  margin-bottom: 2em;
}

.home-page-main-container .colorlib-intro .play {
  width: 100%;
  display: block;
  margin: 30px 0;
}

.home-page-main-container .colorlib-intro .play a {
  width: 100px;
  height: 100px;
  display: table;
  margin: 0 auto;
  text-align: center;
  background: #798eea;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.home-page-main-container .colorlib-intro .play a i {
  font-size: 20px;
  color: #fff;
  display: table-cell;
  vertical-align: middle;
}

.home-page-main-container .pulse {
  display: table;
  width: 100px;
  height: 100px;
  background: #798eea;
  cursor: pointer;
  -webkit-box-shadow: 0 0 0 rgba(121, 142, 234, 0.4);
  box-shadow: 0 0 0 rgba(121, 142, 234, 0.4);
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.home-page-main-container .pulse i {
  font-size: 30px !important;
  display: table-cell;
  vertical-align: middle;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(121, 142, 234, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(121, 142, 234, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(121, 142, 234, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(121, 142, 234, 0.4);
    -webkit-box-shadow: 0 0 0 0 rgba(121, 142, 234, 0.4);
    box-shadow: 0 0 0 0 rgba(121, 142, 234, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 30px rgba(121, 142, 234, 0);
    -webkit-box-shadow: 0 0 0 30px rgba(121, 142, 234, 0);
    box-shadow: 0 0 0 30px rgba(121, 142, 234, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(121, 142, 234, 0);
    -webkit-box-shadow: 0 0 0 0 rgba(121, 142, 234, 0);
    box-shadow: 0 0 0 0 rgba(121, 142, 234, 0);
  }
}

.home-page-main-container .colorlib-work-featured .mobile-wrap {
  margin-bottom: 4em;
  display: block;
}

.home-page-main-container .colorlib-work-featured .mobile-wrap .mobile-img {
  background-position: top;
  height: 730px;
  -webkit-box-shadow: 0px 3px 38px -13px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 38px -13px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 38px -13px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

.home-page-main-container .colorlib-work-featured .mobile-wrap .desc {
  padding: 0 30px 30px 30px;
}

.home-page-main-container .colorlib-work-featured .mobile-wrap .desc h2 {
  font-size: 50px;
  font-weight: 300;
  margin-bottom: 25px;
}

@media screen and (max-width: 768px) {
  .home-page-main-container .colorlib-work-featured .mobile-wrap .desc h2 {
    font-size: 34px;
  }
}

.home-page-main-container .colorlib-work-featured .mobile-wrap .desc .features {
  position: relative;
  margin-bottom: 20px;
}
.home-page-main-container
  .colorlib-work-featured
  .mobile-wrap
  .desc
  .features:last-child {
  margin-bottom: 0px !important;
}
.home-page-main-container .features:last-child p {
  margin-bottom: 0 !important;
}
.detailed-description p {
  margin-left: 20px !important;
}
.home-page-main-container
  .colorlib-work-featured
  .mobile-wrap
  .desc
  .features
  .icon {
  position: absolute;
  top: 10px;
  left: 0;
}
.home-page-main-container
  .colorlib-work-featured
  .mobile-wrap
  .desc
  .features
  .icon
  i {
  font-size: 30px;
  color: #499bea;
}

.home-page-main-container
  .colorlib-work-featured
  .mobile-wrap
  .desc
  .features
  .f-desc {
  padding-left: 50px;
}

.home-page-main-container .work-flex {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  margin-bottom: 40px;
}

.home-page-main-container .work-flex .half {
  display: block;
  width: 50%;
  height: 450px;
}

.home-page-main-container .work-flex .half .display-t {
  height: 450px;
  display: table;
}

@media screen and (max-width: 480px) {
  .home-page-main-container .work-flex .half .display-t {
    display: block;
    height: auto;
  }
}

.home-page-main-container .work-flex .half .display-tc {
  display: table-cell;
  vertical-align: middle;
}

.home-page-main-container .work-flex .half .desc {
  padding: 0 3em;
  background: #fff;
}

@media screen and (max-width: 768px) {
  .home-page-main-container .work-flex .half .desc {
    padding: 3em;
  }
}

.home-page-main-container .work-flex .half .desc h2 {
  font-weight: 400;
}

.home-page-main-container .work-flex .half .desc h2 a {
  color: #303133;
}

@media screen and (max-width: 480px) {
  .home-page-main-container .work-flex .half {
    width: 100%;
  }
}

.home-page-main-container .work-flex .work-img {
  display: block;
  height: 450px;
}

.home-page-main-container .work-grid {
  margin-bottom: 40px;
}

.home-page-main-container .work-grid .work-img {
  display: block;
  height: 270px;
  position: relative;
}

.home-page-main-container .work-grid .work-img .icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 270px;
  content: "";
  display: table;
  text-align: center;
  background: #4aca85;
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.home-page-main-container .work-grid .work-img .icon i {
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  font-size: 20px;
}

.home-page-main-container .work-grid .work-img:hover .icon,
.home-page-main-container .work-grid .work-img:focus .icon {
  opacity: 1;
}

.home-page-main-container .work-grid .desc {
  background: #fff;
  padding: 25px;
}

.home-page-main-container .work-grid .desc h2 {
  font-size: 24px;
}

.home-page-main-container .work-grid .desc h2 a {
  color: #303133;
}

.home-page-main-container .work-grid .desc h2 a:hover {
  color: #4aca85;
}

.home-page-main-container .product-entry {
  margin-bottom: 40px;
}

.home-page-main-container .product-entry .product-img {
  display: block;
  height: 350px;
  margin-bottom: 20px;
  position: relative;
}

.home-page-main-container .product-entry .product-img .tag {
  position: absolute;
  top: 10px;
  left: 10px;
}

.home-page-main-container .product-entry .product-img .tag .new {
  font-size: 11px;
  background: #4aca85;
  color: #fff;
  padding: 0.3em 0.5em;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
}

.home-page-main-container .product-entry .product-img .tag .sale {
  font-size: 11px;
  background: #798eea;
  color: #fff;
  padding: 0.3em 0.5em;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
}

.home-page-main-container .product-entry .product-img .cart {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  display: block;
  padding: 0 1em;
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.home-page-main-container .product-entry .product-img .cart p {
  display: block;
  margin-bottom: 0;
  width: 100%;
  text-transform: uppercase;
  font-size: 12px;
}

.home-page-main-container .product-entry .product-img .cart p span {
  display: inline-block;
}

.home-page-main-container .product-entry .product-img .cart p span a {
  display: block;
  color: #fff;
  padding: 1em;
}

.home-page-main-container .product-entry .product-img .cart p span a:hover {
  background: #4aca85;
}

.home-page-main-container .product-entry .desc h3 {
  font-size: 20px;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 10px;
}

.home-page-main-container .product-entry .desc h3 a {
  color: #000;
}

.home-page-main-container .product-entry .desc .price span {
  color: #4aca85;
  font-weight: 400;
}

.home-page-main-container .product-entry .desc .price .sale {
  color: gray;
  text-decoration: line-through;
  font-weight: 300;
}

.home-page-main-container .product-entry:hover .product-img .cart {
  opacity: 1;
}

.home-page-main-container .staff-entry {
  display: block;
  margin-bottom: 20px;
  z-index: 0;
  background: #fff;
  padding: 30px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

.home-page-main-container .staff-entry .staff-img {
  width: 150px;
  height: 150px;
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.home-page-main-container .staff-entry .desc h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.home-page-main-container .staff-entry .desc span {
  display: block;
  margin-bottom: 20px;
  font-size: 13px;
}

.home-page-main-container .staff-entry .colorlib-social-icons li {
  margin-left: 0;
}

.home-page-main-container .staff-entry .colorlib-social-icons li a {
  padding: 0;
}

.home-page-main-container #colorlib-counter {
  position: relative;
  z-index: -1;
  padding-top: 7em;
  padding-bottom: 7em;
}

.home-page-main-container #colorlib-counter .colorlib-heading {
  margin-bottom: 30px;
}

.home-page-main-container #colorlib-counter .colorlib-heading h2 {
  color: #fff;
}

.home-page-main-container #colorlib-counter .colorlib-heading p {
  font-size: 22px;
  color: rgba(255, 255, 255, 0.8);
}

.home-page-main-container .counter-entry {
  padding: 0;
  position: relative;
}

@media screen and (max-width: 768px) {
  .home-page-main-container .counter-entry {
    margin-bottom: 20px;
  }
}

.home-page-main-container .colorlib-counters {
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
}

.home-page-main-container .colorlib-counters .overlay {
  z-index: 0;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: #499bea;
  background: -moz-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: -webkit-gradient(
    left top,
    right bottom,
    color-stop(0%, #499bea),
    color-stop(100%, #798eea)
  );
  background: -webkit-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: -o-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: -ms-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: -webkit-linear-gradient(315deg, #499bea 0%, #798eea 100%);
  background: -o-linear-gradient(315deg, #499bea 0%, #798eea 100%);
  background: linear-gradient(135deg, #499bea 0%, #798eea 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#499bea', endColorstr='#798eea', GradientType=1);
  opacity: 0.96;
}

.home-page-main-container .colorlib-counters .icon {
  margin-bottom: 20px;
  width: 100px;
  z-index: 0;
}

.home-page-main-container .colorlib-counters .icon i {
  font-size: 50px;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
}

.home-page-main-container .colorlib-counters .colorlib-counter {
  font-size: 75px;
  display: block;
  color: #fff;
  font-family: "Nunito", Arial, sans-serif;
  width: 100%;
  font-weight: 300;
  margin-bottom: 0;
}

.home-page-main-container .colorlib-counters .colorlib-counter-label {
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  display: block;
  font-weight: 300;
}

.home-page-main-container article {
  background: #fff;
  padding: 35px;
  margin-bottom: 40px;
  -webkit-box-shadow: 0px 3px 38px -13px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 38px -13px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 38px -13px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

.home-page-main-container article h2 {
  font-weight: 400;
  margin-bottom: 15px;
}

.home-page-main-container article h2 a {
  color: #303133;
}

.home-page-main-container article .admin span {
  font-size: 12px;
  color: #303133;
}

.home-page-main-container article p.author-wrap {
  position: relative;
  margin-top: 30px;
}

.home-page-main-container article p.author-wrap .author-img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.home-page-main-container article p.author-wrap .author {
  padding-left: 40px;
  color: #303133;
  font-size: 12px;
}

.home-page-main-container .pricing {
  background: #fff;
  padding: 2em 1em 1em 1em;
  -webkit-box-shadow: 0px 3px 38px -13px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 38px -13px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 38px -13px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

.home-page-main-container .pricing .pricing-heading {
  margin-bottom: 30px;
}

.home-page-main-container .pricing .price {
  font-size: 72px;
  color: #4aca85;
  line-height: 50px;
  font-weight: 300;
}

.home-page-main-container .pricing .price .currency {
  font-size: 30px;
  top: -0.9em;
  padding-right: 10px;
}

.home-page-main-container .pricing .price small {
  font-size: 13px;
  display: block;
  color: #888f94;
}

.home-page-main-container #colorlib-subscribe {
  position: relative;
}

.home-page-main-container .colorlib-subscribe {
  clear: both;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
}

.home-page-main-container .colorlib-subscribe .overlay {
  z-index: 0;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  /* background: #499bea;
	background: -moz-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
	background: -webkit-gradient(left top, right bottom, color-stop(0%, #499bea), color-stop(100%, #798eea));
	background: -webkit-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
	background: -o-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
	background: -ms-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
	background: -webkit-linear-gradient(315deg, #499bea 0%, #798eea 100%);
	background: -o-linear-gradient(315deg, #499bea 0%, #798eea 100%);
	background: linear-gradient(135deg, #499bea 0%, #798eea 100%); */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#499bea', endColorstr='#798eea', GradientType=1);
  background-image: linear-gradient(
    134deg,
    #3266c6 0%,
    #00b1e2 100%
  ) !important;
  opacity: 0.96;
}

.home-page-main-container .colorlib-subscribe .colorlib-heading {
  margin-bottom: 30px;
}

.home-page-main-container .colorlib-subscribe .colorlib-heading h2 {
  color: #fff;
}

.home-page-main-container .colorlib-subscribe .colorlib-heading p {
  font-size: 20px;
  color: rgba(255, 255, 255);
}

.home-page-main-container .qbstp-header-subscribe {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  width: 100%;
  padding: 0;
}

.home-page-main-container .qbstp-header-subscribe .col-three-forth {
  width: 70%;
}

.home-page-main-container .qbstp-header-subscribe .col-one-third {
  width: 30%;
}

.home-page-main-container .qbstp-header-subscribe .col-three-forth,
.home-page-main-container .qbstp-header-subscribe .col-one-third {
  display: table;
  height: 50px;
  float: left;
}

@media screen and (max-width: 768px) {
  .home-page-main-container .qbstp-header-subscribe .col-three-forth,
  .home-page-main-container .qbstp-header-subscribe .col-one-third {
    width: 50%;
  }
}

.home-page-main-container .qbstp-header-subscribe .form-control {
  background: #fff;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.home-page-main-container .qbstp-header-subscribe .form-group {
  position: relative;
  width: 100%;
  display: table-cell;
  vertical-align: middle;
}

@media screen and (max-width: 480px) {
  .home-page-main-container .qbstp-header-subscribe .form-group {
    width: 100%;
  }
}

.home-page-main-container
  .qbstp-header-subscribe
  .form-group
  input::-webkit-input-placeholder {
  color: #4d4d4d;
}

.home-page-main-container
  .qbstp-header-subscribe
  .form-group
  input::-moz-input-placeholder {
  color: #4d4d4d;
}

.home-page-main-container
  .qbstp-header-subscribe
  .form-group
  input::-ms-input-placeholder {
  color: #4d4d4d;
}

.home-page-main-container
  .qbstp-header-subscribe
  .form-group
  input::-o-input-placeholder {
  color: #4d4d4d;
}

.home-page-main-container
  .qbstp-header-subscribe
  .form-group
  input::input-placeholder {
  color: #4d4d4d;
}

.home-page-main-container .qbstp-header-subscribe #email {
  font-size: 14px;
  width: 100%;
  border: none;
  border-bottom: transparent;
  color: #4d4d4d !important;
}

.home-page-main-container .qbstp-header-subscribe button {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  background: #4aca85;
  color: #fff;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  height: 50px;
  width: 100%;
  display: block;
}

.home-page-main-container .no-gutters {
  margin: 0 !important;
}

.home-page-main-container .pagination li {
  margin: 2px;
}

.home-page-main-container .pagination li a {
  color: #000;
  background: #fff;
  color: #000;
  border: 1px solid #e6e6e6;
  margin: 2px;
}

.home-page-main-container .pagination li a:first-child {
  border: 1px solid #e6e6e6;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
}

.home-page-main-container .pagination li a:hover,
.home-page-main-container .pagination li a:focus {
  background: #4aca85;
  color: #fff;
  border: 1px solid #4aca85;
}

@media screen and (max-width: 768px) {
  .home-page-main-container .pagination li a {
    padding: 7px 15px;
  }
}

.home-page-main-container .pagination li.active a {
  background: #4aca85;
  border: 1px solid #4aca85;
}

.home-page-main-container .pagination li.active a:hover,
.home-page-main-container .pagination li.active a:focus {
  background: #4aca85;
  color: #fff;
  border: 1px solid #4aca85;
}

.home-page-main-container .no-gutters {
  margin: 0 !important;
  padding: 0 !important;
}

.home-page-main-container .f-blog {
  position: relative;
  padding-left: 80px;
  margin-bottom: 40px;
}

.home-page-main-container .f-blog .blog-img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 70px;
  height: 60px;
  float: left;
}

.home-page-main-container .f-blog .desc h2 {
  font-size: 14px;
  margin-bottom: 5px;
}

.home-page-main-container .f-blog .desc h2 a {
  color: #fff;
}

.home-page-main-container .f-blog .desc .admin {
  font-size: 12px;
}

.home-page-main-container .contact-info-wrap-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  width: 100%;
  margin-bottom: 3em;
}

.home-page-main-container .contact-info-wrap-flex .con-info {
  width: 100%;
  background: #fff;
  padding: 1.5em;
  background: white;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .home-page-main-container .contact-info-wrap-flex .con-info {
    width: 100%;
    margin: 4px !important;
  }
}

.home-page-main-container .contact-info-wrap-flex p {
  margin-bottom: 0;
  color: #000;
}

.home-page-main-container .contact-info-wrap-flex p span i {
  color: #4aca85;
}

.home-page-main-container .contact-info-wrap-flex p a {
  color: #000;
}

.home-page-main-container .form-control {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  border: 1px solid #f0f0f0;
  font-size: 16px !important;
  font-weight: 300;
  background: white;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
}

.home-page-main-container .form-control:focus,
.home-page-main-container .form-control:active {
  background: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e6e6e6;
}

.home-page-main-container input[type="text"] {
  height: 50px;
}

.home-page-main-container .form-group {
  margin-bottom: 30px;
}

.home-page-main-container .form-group label {
  font-weight: 400;
}

.home-page-main-container .form-group .btn-modify {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 13px;
  padding: 10px 15px;
}

.home-page-main-container .colorlib-social-icons {
  margin: 0;
  padding: 0;
}

.home-page-main-container .colorlib-social-icons li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.home-page-main-container .colorlib-social-icons li a {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  color: #4aca85;
  padding-left: 10px;
  padding-right: 10px;
}

.home-page-main-container .colorlib-social-icons li a i {
  font-size: 20px;
}

.home-page-main-container .colorlib-heading {
  margin-bottom: 5em;
}

.home-page-main-container .colorlib-heading h2 {
  font-size: 50px;
  font-weight: 300;
  margin-bottom: 30px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .home-page-main-container .colorlib-heading h2 {
    font-size: 30px;
  }
}

.home-page-main-container #colorlib-footer {
  background: #303133;
  color: rgba(255, 255, 255, 0.6) !important;
  padding-top: 6em;
}

.home-page-main-container #colorlib-footer .colorlib-widget p {
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .home-page-main-container #colorlib-footer .colorlib-widget {
    margin-bottom: 40px;
    width: 100%;
    float: left;
  }
}

.home-page-main-container #colorlib-footer .colorlib-footer-links {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .home-page-main-container #colorlib-footer .colorlib-footer-links {
    margin-bottom: 30px;
  }
}

.home-page-main-container #colorlib-footer .colorlib-footer-links li {
  padding: 0;
  margin: 0 0 10px 0;
  list-style: none;
  display: block;
  font-size: 13px;
}

.home-page-main-container #colorlib-footer .colorlib-footer-links li a {
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
}

.home-page-main-container #colorlib-footer .colorlib-footer-links li a i {
  padding-right: 10px;
  color: #fff;
}

.home-page-main-container #colorlib-footer .colorlib-social-icons li a {
  padding-left: 10px;
  padding-right: 10px;
}

.home-page-main-container
  #colorlib-footer
  .colorlib-social-icons
  li
  a:first-child {
  padding-left: 0;
}

.home-page-main-container #colorlib-footer h4 {
  margin-bottom: 30px;
  font-size: 12px;
  color: #e6e6e6;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 700;
}

.home-page-main-container #colorlib-footer .copy {
  background: #262626;
  padding: 7em 0;
}

.home-page-main-container #colorlib-footer .copy p {
  margin-bottom: 0;
  color: #fff;
  font-size: 13px;
}

.home-page-main-container #colorlib-footer .copy p small a {
  color: rgba(255, 255, 255, 0.7);
}

.home-page-main-container #map {
  width: 100%;
  height: 500px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .home-page-main-container #map {
    height: 200px;
  }
}

.home-page-main-container #colorlib-offcanvas {
  position: absolute;
  z-index: 1901;
  width: 270px;
  background: #1a1a1a;
  top: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 75px 40px 40px 40px;
  overflow-y: auto;
  display: none;
  -moz-transform: translateX(270px);
  -webkit-transform: translateX(270px);
  -ms-transform: translateX(270px);
  -o-transform: translateX(270px);
  transform: translateX(270px);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

@media screen and (max-width: 768px) {
  .home-page-main-container #colorlib-offcanvas {
    display: block;
  }
}

.home-page-main-container .offcanvas #colorlib-offcanvas {
  -moz-transform: translateX(0px);
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
}

.home-page-main-container #colorlib-offcanvas a {
  color: rgba(255, 255, 255, 0.5);
}

.home-page-main-container #colorlib-offcanvas a:hover {
  color: rgba(255, 255, 255, 0.8);
}

.home-page-main-container #colorlib-offcanvas ul {
  padding: 0;
  margin: 0;
}

.home-page-main-container #colorlib-offcanvas ul li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.home-page-main-container #colorlib-offcanvas ul li > ul {
  padding-left: 20px;
  display: none;
}

.home-page-main-container #colorlib-offcanvas ul li.offcanvas-has-dropdown > a {
  display: block;
  position: relative;
}

.home-page-main-container
  #colorlib-offcanvas
  ul
  li.offcanvas-has-dropdown
  > a:after {
  position: absolute;
  right: 0px;
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e0c5";
  font-size: 20px;
  color: rgba(255, 255, 255, 0.2);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.home-page-main-container
  #colorlib-offcanvas
  ul
  li.offcanvas-has-dropdown.active
  a:after {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.home-page-main-container #colorlib-offcanvas ul li.btn-cta {
  margin-top: 5px;
}

.home-page-main-container #colorlib-offcanvas ul li.btn-cta a {
  border-bottom: 1px solid #4aca85;
  padding-bottom: 5px;
}

.home-page-main-container .gototop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.home-page-main-container .gototop.active {
  opacity: 1;
  visibility: visible;
}

.home-page-main-container .gototop a {
  width: 50px;
  height: 50px;
  display: table;
  background: black;
  color: #fff;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.home-page-main-container .gototop a i {
  height: 50px;
  display: table-cell;
  vertical-align: middle;
}

.home-page-main-container .gototop a:hover,
.home-page-main-container .gototop a:active,
.home-page-main-container .gototop a:focus {
  text-decoration: none;
  outline: none;
}

.home-page-main-container .colorlib-nav-toggle {
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-decoration: none;
  top: 1.2em !important;
}

.home-page-main-container .colorlib-nav-toggle.active i::before,
.home-page-main-container .colorlib-nav-toggle.active i::after {
  background: #303133;
}

.home-page-main-container .colorlib-nav-toggle:hover,
.home-page-main-container .colorlib-nav-toggle:focus,
.home-page-main-container .colorlib-nav-toggle:active {
  outline: none;
  border-bottom: none !important;
}

.home-page-main-container .colorlib-nav-toggle i {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 2px;
  color: #252525;
  font: bold 14px/0.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: #252525;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.home-page-main-container .colorlib-nav-toggle i::before,
.home-page-main-container .colorlib-nav-toggle i::after {
  content: "";
  width: 25px;
  height: 2px;
  background: #252525;
  position: absolute;
  left: 0;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.home-page-main-container .colorlib-nav-toggle.colorlib-nav-white > i {
  color: #fff;
  background: #fff;
}

.home-page-main-container .colorlib-nav-toggle.colorlib-nav-white > i::before,
.home-page-main-container .colorlib-nav-toggle.colorlib-nav-white > i::after {
  background: #fff;
}

.home-page-main-container .colorlib-nav-toggle i::before {
  top: -7px;
}

.home-page-main-container .colorlib-nav-toggle i::after {
  bottom: -7px;
}

.home-page-main-container .colorlib-nav-toggle:hover i::before {
  top: -10px;
}

.home-page-main-container .colorlib-nav-toggle:hover i::after {
  bottom: -10px;
}

.home-page-main-container .colorlib-nav-toggle.active i {
  background: transparent;
}

.home-page-main-container .colorlib-nav-toggle.active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  background: #fff;
}

.home-page-main-container .colorlib-nav-toggle.active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
  background: #fff;
}

.home-page-main-container .colorlib-nav-toggle {
  position: absolute;
  right: 0px;
  top: 65px;
  z-index: 21;
  padding: 6px 0 0 0;
  display: block;
  margin: 0 auto;
  display: none;
  height: 44px;
  width: 44px;
  z-index: 2001;
  border-bottom: none !important;
}

.home-page-main-container .btn {
  margin-right: 4px;
  /* margin-bottom: 4px; */
  font-size: 14px;
  font-weight: 400;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 8px 20px;
}

.home-page-main-container .btn.btn-md {
  padding: 8px 20px !important;
}

.home-page-main-container .btn.btn-lg {
  padding: 18px 36px !important;
}

.home-page-main-container .btn:hover,
.home-page-main-container .btn:active,
.home-page-main-container .btn:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.home-page-main-container .btn-primary {
  background: #375ebf;
  color: #fff;
  border: 1px solid #375ebf;
}

.home-page-main-container .btn-primary:hover,
.home-page-main-container .btn-primary:focus,
.home-page-main-container .btn-primary:active {
  background: #375ebf !important;
  border-color: #375ebf !important;
  color: white !important;
}

.home-page-main-container .btn-primary.btn-outline {
  background: transparent;
  color: #375ebf;
  border: 1px solid #375ebf;
}

.home-page-main-container .btn-primary.btn-outline:hover,
.home-page-main-container .btn-primary.btn-outline:focus,
.home-page-main-container .btn-primary.btn-outline:active {
  background: #375ebf;
  color: #fff;
}

.home-page-main-container .btn-success {
  background: #375ebf;
  color: #fff;
  border: 1px solid #375ebf;
}

.home-page-main-container .btn-success:hover,
.home-page-main-container .btn-success:focus,
.home-page-main-container .btn-success:active {
  background: #375ebf !important;
  border-color: #375ebf !important;
}

.home-page-main-container .btn-success.btn-outline {
  background: transparent;
  color: #375ebf;
  border: 2px solid #375ebf;
}

.home-page-main-container .btn-success.btn-outline:hover,
.home-page-main-container .btn-success.btn-outline:focus,
.home-page-main-container .btn-success.btn-outline:active {
  background: #375ebf;
  color: #fff;
}

.home-page-main-container .btn-info {
  background: #5bc0de;
  color: #fff;
  border: 1px solid #5bc0de;
}

.home-page-main-container .btn-info:hover,
.home-page-main-container .btn-info:focus,
.home-page-main-container .btn-info:active {
  background: #46b8da !important;
  border-color: #46b8da !important;
}

.home-page-main-container .btn-info.btn-outline {
  background: transparent;
  color: #5bc0de;
  border: 1px solid #5bc0de;
}

.home-page-main-container .btn-info.btn-outline:hover,
.home-page-main-container .btn-info.btn-outline:focus,
.home-page-main-container .btn-info.btn-outline:active {
  background: #5bc0de;
  color: #fff;
}

.home-page-main-container .btn-warning {
  background: #f0ad4e;
  color: #fff;
  border: 1px solid #f0ad4e;
}

.home-page-main-container .btn-warning:hover,
.home-page-main-container .btn-warning:focus,
.home-page-main-container .btn-warning:active {
  background: #eea236 !important;
  border-color: #eea236 !important;
}

.home-page-main-container .btn-warning.btn-outline {
  background: transparent;
  color: #f0ad4e;
  border: 1px solid #f0ad4e;
}

.home-page-main-container .btn-warning.btn-outline:hover,
.home-page-main-container .btn-warning.btn-outline:focus,
.home-page-main-container .btn-warning.btn-outline:active {
  background: #f0ad4e;
  color: #fff;
}

.home-page-main-container .btn-danger {
  background: #d9534f;
  color: #fff;
  border: 1px solid #d9534f;
}

.home-page-main-container .btn-danger:hover,
.home-page-main-container .btn-danger:focus,
.home-page-main-container .btn-danger:active {
  background: #d43f3a !important;
  border-color: #d43f3a !important;
}

.home-page-main-container .btn-danger.btn-outline {
  background: transparent;
  color: #d9534f;
  border: 1px solid #d9534f;
}

.home-page-main-container .btn-danger.btn-outline:hover,
.home-page-main-container .btn-danger.btn-outline:focus,
.home-page-main-container .btn-danger.btn-outline:active {
  background: #d9534f;
  color: #fff;
}

.home-page-main-container .btn-outline {
  background: none;
  border: 1px solid gray;
  font-size: 16px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.home-page-main-container .btn-outline:hover,
.home-page-main-container .btn-outline:focus,
.home-page-main-container .btn-outline:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.home-page-main-container .btn.with-arrow {
  position: relative;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.home-page-main-container .btn.with-arrow i {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -8px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.home-page-main-container .btn.with-arrow:hover {
  padding-right: 50px;
}

.home-page-main-container .btn.with-arrow:hover i {
  color: #fff;
  right: 18px;
  visibility: visible;
  opacity: 1;
}

.home-page-main-container .row-pb-lg {
  padding-bottom: 7em !important;
}

.home-page-main-container .row-pb-md {
  padding-bottom: 5em !important;
}

.home-page-main-container .row-pb-sm {
  padding-bottom: 3em !important;
}

.home-page-main-container .nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.home-page-main-container .colorlib-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url(../images/loader.gif) center no-repeat #fff;
}

.home-page-main-container .js .animate-box {
  opacity: 0;
}

body {
  line-height: 1.4 !important;
}

.home-page-main-container .home-background-image {
  background-image: url("../images/header_bg.jpg") !important;
  background-size: cover !important;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  width: 100% !important;
  min-height: 100vh !important;
}

.home-page-main-container .video-hero .overlay {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  left: 0px !important;
  top: 0px !important;
  z-index: 0 !important;
  background: rgba(63, 81, 181, 0.75);
}

.home-page-main-container .video-hero .display-tc h2 {
  font-size: 32px !important;
  line-height: 32px !important;
}

.home-page-main-container .video-hero .display-tc p {
  font-size: 20px !important;
  line-height: 26px !important;
}

.home-page-main-container .home-link-button,
.home-page-main-container .home-link-button:hover {
  background: transparent !important;
  border: 1px solid #fff !important;
  margin-top: 10px !important;
  border-radius: 0;
}

.home-page-main-container .colorlib-featured {
  padding: 7em 0 !important;
  max-height: 1000px !important;
}

.home-page-main-container .home-heading-text {
  color: White !important;
  font-size: 22px !important;
  line-height: 28px !important;
  margin-top: 25px !important;
}

.home-page-main-container .video-hero .display-t {
  height: 100vh !important;
}

.home-page-main-container .carousel .thumbs-wrapper {
  margin-bottom: 0 !important;
}

.home-page-main-container ul.thumbs {
  margin-bottom: 0 !important;
}

.home-page-main-container .photo-gallery-text {
  margin-bottom: 20px !important;
}

.home-page-main-container .step-text {
  font-size: 18px !important;
}

.home-page-main-container .services {
  margin-bottom: 0px !important;
}

.home-page-main-container .side-image {
  background-image: url("https://colorlib.com/preview/theme/unapp/images/mobile-2.jpg");
}

.home-page-main-container .row.mobile-wrap:after {
  clear: both;
}

.home-page-main-container .row.mobile-wrap:before,
.home-page-main-container .row.mobile-wrap:after {
  content: " ";
  display: table;
}

.home-page-main-container .homecontainer:before,
.home-page-main-container .homecontainer:after {
  content: " ";
  display: table;
}

.home-page-main-container .colorlib-work-featured .mobile-wrap {
  margin-bottom: 0 !important;
}

.home-page-main-container .video-heading {
  font-size: 25px !important;
}

.home-page-main-container #colorlib-footer .copy {
  padding: 1em 0 !important;
}

.home-page-main-container #colorlib-footer {
  padding-top: 0 !important;
}

.home-page-main-container .marginleftrightauto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.home-page-main-container .font-size-16 {
  font-size: 16px !important;
}

.home-page-main-container .colorlib-subscribe {
  background-image: linear-gradient(134deg, #3266c6 0%, #00b1e2 100%);
}

.home-page-main-container .qbstp-header-subscribe button {
  background: #255a9f !important;
}

.slider-text {
  font-size: 18px !important;
}

.body-text {
  line-height: 22px !important;
}

.cubewrap {
  margin-top: 10px;
  perspective: 1000px;
  perspective-origin: 50% 50%;
}
.cube {
  margin: auto;
  position: relative;
  height: 100px;
  width: 100px;
  transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}
.cube div {
  position: absolute;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border: solid 1px #eeeeee;
  color: #ffffff;
}
.front {
  transform: translateZ(5px);
  -ms-transform: translateZ(50px);
  -webkit-transform: translateZ(50px);
  background-color: #31549d;
  background-size: 120%;
}

.back {
  transform: translateZ(-50px) rotateY(180deg);
  -webkit-transform: translateZ(-50px) rotateY(180deg);
  -ms-transform: translateZ(-50px) rotateY(180deg);
  background-color: #31549d;
  background-size: 120%;
}

.right {
  transform: rotateY(-270deg) translateX(50px);
  -ms-transform: rotateY(-270deg) translateX(50px);
  -webkit-transform: rotateY(-270deg) translateX(50px);
  transform-origin: top right;
  -ms-transform-origin: top right;
  -webkit-transform-origin: top right;
  background-color: #31549d;
  background-size: 160%;
}

.left {
  transform: rotateY(270deg) translateX(-50px);
  -webkit-transform: rotateY(270deg) translateX(-50px);
  -ms-transform: rotateY(270deg) translateX(-50px);
  transform-origin: center left;
  -ms-transform-origin: center left;
  -webkit-transform-origin: center left;
  background-color: #31549d;
  background-size: 160%;
}

.top {
  transform: rotateX(-270deg) translateY(-50px);
  -webkit-transform: rotateX(-270deg) translateY(-50px);
  -ms-transform: rotateX(-270deg) translateY(-50px);
  transform-origin: top center;
  -ms-transform-origin: top center;
  -webkit-transform-origin: top center;
  background-color: #31549d;
  background-size: 120%;
}

.bottom {
  transform: rotateX(270deg) translateY(50px);
  -webkit-transform: rotateX(270deg) translateY(50px);
  -ms-transform: rotateX(270deg) translateY(50px);
  transform-origin: bottom center;
  -ms-transform-origin: bottom center;
  -webkit-transform-origin: bottom center;
  background-color: #31549d;
  background-size: 120%;
}
@keyframes rotate {
  from {
    transform: rotateX(0deg) rotateY(0deg);
    -ms-transform: rotateX(0deg) rotateY(0deg);
    -webkit-transform: rotateX(0deg) rotateY(0deg);
  }

  to {
    transform: rotateX(360deg) rotateY(360deg);
    -ms-transform: rotateX(360deg) rotateY(360deg);
    -webkit-transform: rotateX(360deg) rotateY(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    transform: rotateX(0deg) rotateY(0deg);
    -ms-transform: rotateX(0deg) rotateY(0deg);
    -webkit-transform: rotateX(0deg) rotateY(0deg);
  }

  to {
    transform: rotateX(360deg) rotateY(360deg);
    -ms-transform: rotateX(360deg) rotateY(360deg);
    -webkit-transform: rotateX(360deg) rotateY(360deg);
  }
}

.cube {
  animation: rotate 20s infinite linear;
  -webkit-animation: rotate 20s infinite linear;
}

.lockerimage {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: auto;
  margin-top: 25%;
}

.cube-details {
  font-size: 16px !important;
  line-height: 1.9 !important;
  font-weight: 300;
}

.live-demo-button {
  background-color: #31549d !important;
  border: 1px solid #31549d !important;
  margin-top: 10px !important;
  border-radius: 0 !important;
}

.cube-register-text {
  font-size: 18px !important;
  font-weight: bolder !important;
}

.interest-form {
  max-width: 750px !important;
  width: 750px !important;
}

.learn-more-link,
.learn-more-link:hover,
.learn-more-link:focus {
  border: 1px solid #31549d !important;
  color: #31549d !important;
  background: transparent !important;
  margin-top: 10px !important;
  border-radius: 0 !important;
  padding: 9px 18px !important;
}

.interest-modal-container .subscribe-form {
  border-radius: 4px;
  position: relative;
  z-index: 2;
}
.interest-modal-container .subscribe-form .section-title {
  font-weight: 500;
  font-size: 18px;
  color: #3b566e;
  letter-spacing: 0.77px;
  margin-bottom: 30px;
}
.interest-modal-container .subscribe-form .form-element input,
.subscribe-form .form-element textarea,
.subscribe-form .form-element select {
  width: 100%;
  height: 46px;
  border: 1px solid #cee1f8;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 24px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.interest-modal-container .subscribe-form select {
  color: inherit !important;
}
.interest-modal-container .subscribe-form .form-element input:focus,
.subscribe-form .form-element textarea:focus {
  padding-left: 20px;
  border: 1px solid #3266c6;
}
.interest-modal-container .subscribe-form .form-element textarea {
  height: 120px;
  padding: 20px;
  resize: none;
}
.interest-modal-container .subscribe-form .form-element button {
  width: 25% !important;
}
.interest-modal-container .subscribe-form .form-element button:hover {
  border: 1px solid #3759c1;
  color: #3759c1;
}
.interest-modal-container .subscribe-form input.error,
.subscribe-form textarea.error {
  border: 1px solid red;
}
.interest-modal-container .subscribe-form label.error {
  display: none !important;
}
.height-70 {
  height: 70px !important;
}
.borderradius0 {
  border-radius: 0 !important;
}

#rc-imageselect,
.grecaptcha {
  transform: scale(0.77);
  -webkit-transform: scale(0.77);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}

.positionfixed {
  position: fixed !important;
}

.supplier-home-page-container .mt-5 {
  margin-top: 3rem !important;
}

@media (min-width: 1200px) {
  .home-page-main-container .homecontainer {
    width: 1170px;
  }
}

@media (min-width: 992px) {
  .home-page-main-container .col-md-custom-offset-2 {
    margin-left: 16.66667%;
  }
  .home-page-main-container .col-custom-md-5 {
    width: 41.66667% !important;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .home-page-main-container .col-custom-md-7 {
    width: 58.33333% !important;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  .home-page-main-container .col-md-custom-8 {
    width: 66.66667%;
  }
}

@media screen and (max-width: 768px) {
  .home-page-main-container .colorlib-nav-toggle {
    display: block;
  }
  .home-page-main-container .form-group #fname {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .home-page-main-container .video-hero .display-tc h2 {
    font-size: 18px !important;
    line-height: 25px !important;
  }
  .home-page-main-container .home-heading-text {
    font-size: 16px !important;
    line-height: 22px !important;
  }
  .home-page-main-container .video-hero .display-tc p {
    font-size: 16px !important;
    line-height: 22px !important;
  }
  .colorlib-services .animate-box {
    margin-bottom: 20px !important;
  }
  .colorlib-services .animate-box:last-child {
    margin-bottom: 0px !important;
  }
  .home-page-main-container.home-page-container {
    line-height: 1.5 !important;
  }
  .home-page-main-container .colorlib-about,
  .home-page-main-container .colorlib-shop,
  .home-page-main-container .colorlib-work,
  .home-page-main-container .colorlib-work-featured,
  .home-page-main-container .colorlib-intro,
  .home-page-main-container #colorlib-subscribe,
  .home-page-main-container .colorlib-pricing,
  .home-page-main-container .colorlib-services,
  .home-page-main-container #colorlib-contact,
  .home-page-main-container .colorlib-blog,
  .home-page-main-container .colorlib-feature {
    padding: 3em 0 !important;
  }
  .home-page-main-container .font-size-16 {
    font-size: 14px !important;
  }
  .home-page-main-container .colorlib-heading h2 {
    font-size: 25px !important;
  }
  .home-page-main-container .colorlib-work-featured .mobile-wrap .desc {
    padding: 0 15px 15px 15px !important;
  }
  .home-page-main-container .colorlib-heading h2 {
    margin-bottom: 20px;
  }
  .home-page-main-container .qbstp-header-subscribe button {
    border-radius: 50px !important;
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
    margin-top: 15px !important;
  }
  .home-page-main-container .qbstp-header-subscribe {
    width: 100%;
  }
  .qbstp-header-subscribe .col-three-forth {
    width: 100%;
    margin: 0 20px;
  }
  .home-page-main-container .qbstp-header-subscribe #email {
    width: 100%;
  }
  .home-page-main-container .qbstp-header-subscribe .form-control {
    border-radius: 50px;
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
  }
  .home-page-main-container .qbstp-header-subscribe .col-one-third {
    display: inline-block !important;
    margin: 0 auto !important;
    border-radius: 50px !important;
  }
  .home-page-main-container .colorlib-subscribe .colorlib-heading {
    margin-bottom: 10px !important;
  }
  .home-page-main-container .qbstp-header-subscribe .col-three-forth {
    width: 100% !important;
  }
  .home-page-main-container .colorlib-subscribe .colorlib-heading p {
    padding: 0 20px !important;
  }
  .home-page-main-container .qbstp-header-subscribe .col-one-third {
    width: 40% !important;
  }
  .interest-modal-container
    .onboarding-modal
    .onboarding-slide
    .onboarding-content:not(.tokenpayment-content-modal) {
    padding: 20px !important;
  }
  .interest-form {
    width: 320px !important;
    max-width: 320px !important;
  }
  .home-page-main-container .home-background-image {
    background-attachment: initial !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .home-page-main-container .video-hero .display-tc h2 {
    margin-top: 3em;
  }
}
@media screen and (min-width: 768px) and (max-width: 1038px) {
  .home-page-main-container .qbstp-header-subscribe .col-three-forth {
    width: 60%;
  }
  .home-page-main-container .qbstp-header-subscribe .col-one-third {
    width: 20%;
  }
}

@media screen and (min-width: 768px) and (max-width: 785px) {
  .home-page-main-container .qbstp-header-subscribe .col-three-forth {
    width: 59%;
  }
  .home-page-main-container .qbstp-header-subscribe .col-one-third {
    width: 10%;
  }
}

@media screen and (min-width: 576px) and (max-width: 632px) {
  .home-page-main-container .qbstp-header-subscribe .col-three-forth {
    width: 40%;
  }
  .home-page-main-container .qbstp-header-subscribe .col-one-third {
    width: 40%;
  }
}
